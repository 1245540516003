import React from 'react';
import Image from '../elements/Image';
import VideoModal from '../global/VideoModal';

const MediaWithCaption = ({ caption, image, media, video }) => {
  return (
    <div className="mb-[48px] md:mb-[73px] max-w-[792px] mx-auto">
      {media === 'video' ? (
        <VideoModal {...video} caption={caption} />
      ) : (
        <>
          <Image image={image} />
          <div className="mt-[29px] text-blue">{caption}</div>
        </>
      )}
    </div>
  );
};

export default MediaWithCaption;
