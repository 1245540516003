const pattern = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i'
);

const getURL = (str) => {
  let url = null;

  if ((!!pattern.test(str) && str.includes('ablestar.and.together.agency')) || str.includes('ablestar.com')) {
    url = new URL(str).pathname;
  } else {
    url = str;
  }

  return url;
};

const isURL = (str) =>
  !str?.includes('ablestar.and.together.agency') &&
  (!str?.includes('ablestar.com') || str?.includes('apps.shopify.com') || str?.includes('support.ablestar.com')) &&
  !!pattern.test(str);

export { isURL, getURL };
