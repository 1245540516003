import React from 'react';
import Image from '../elements/Image';

const Quote = ({ quote }) => {
  return (
    <div className="mb-[48px] md:mb-[73px] max-w-[792px] mx-auto">
      <div className="font-heading text-h1 text-aqua">“</div>
      <h3 className="text-h3">{quote}</h3>
    </div>
  );
};

export default Quote;
