import React, { useRef, useState } from 'react';
import { AnimatePresence, domAnimation, LazyMotion, m, motion } from 'framer-motion';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';

import { Section } from '~/components/elements/Section';
import { WpPage_Flexiblecontent_FlexibleContent_Testimonials } from '~/_generated/types';
import AnimatedArrow from '../elements/animations/AnimatedArrow';
import { Icon, IconType } from '../elements/Icon';
import Image from '../elements/Image';
import { rotate } from 'tailwindcss/defaultTheme';

export default function Testimonials({ section, testimonials }: WpPage_Flexiblecontent_FlexibleContent_Testimonials) {
  SwiperCore.use([Navigation, Pagination, Autoplay]);
  const testSlider = useRef(null);

  const [activeTab, setActiveTab] = useState(0);

  return (
    <Section {...section}>
      <div className="container remove-padding-mobile">
        <div className="md:rounded-[10px]  default-blue-gradient">
          <div className="md:py-[99px] py-[70px] px-6 md:pl-[108px] md:pr-[87.5px] noisy  ">
            <div className="justify-between md:flex">
              <Swiper
                onActiveIndexChange={(i) => setActiveTab(i.activeIndex)}
                className="relative z-40 flex flex-col w-full"
                slidesPerView={1}
                ref={testSlider}
                grabCursor={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{
                  type: 'progressbar',
                }}
              >
                {testimonials?.map((test, i) => (
                  <SwiperSlide key={`testSlider${i}`} style={{ height: 'auto' }}>
                    <div className="max-w-[737px]">
                      <div className="text-testimonial">{test?.typeTestimonial?.quote}</div>
                      <div className="md:mt-[56px]">{test?.typeTestimonial?.atttribution}</div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="mt-[30px] md:mt-[52px] flex items-center ">
                  <div className="flex items-center justify-center">
                    <AnimatedGreenButton
                      onClick={() => testSlider.current.swiper.slidePrev()}
                      className={'mr-[6px] '}
                      activeTab={activeTab === 0}
                      rotate={0}
                    />

                    <AnimatedGreenButton
                      onClick={() => testSlider.current.swiper.slideNext()}
                      rotate={180}
                      className={' mr-[15px]'}
                      activeTab={activeTab === testimonials?.length - 1}
                    />
                  </div>
                  <div className="flex items-center justify-center space-x-2 md:justify-start md:mt-0">
                    {testimonials?.map((pag, i) => (
                      <div key={`pag${i}`} className="w-[74px] bg-black bg-opacity-20 h-[2px] relative">
                        <AnimatePresence>
                          {activeTab === i && (
                            <motion.div
                              key={`bg${i}`}
                              initial={{ width: '0%', opacity: 1 }}
                              animate={{ width: '100%' }}
                              transition={{ duration: 5, ease: 'linear' }}
                              className="absolute inset-0 bg-black"
                            ></motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    ))}
                  </div>
                </div>
              </Swiper>
              <div className="relative self-end mt-12 md:mt-0 ">
                <div className="flex items-center justify-center mx-auto md:mx-0 bg-white rounded-full h-[185px] w-[185px]">
                  <Image
                    image={testimonials[activeTab]?.typeTestimonial?.logo}
                    className="max-w-[108px] w-full h-full"
                  />
                </div>
                <div className="hidden md:block absolute  top-[-6rem] left-[1rem] rotate-[248deg] scale-x-[-1] lg:scale-x-[1]  lg:rotate-0 lg:top-[3rem] lg:left-[-10rem]">
                  <AnimatedArrow />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export const AnimatedGreenButton = ({ className, activeTab, onClick, rotate }) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={{ rotate: rotate }}
        whileTap={{ scale: 0.875 }}
        whileHover={{ scale: 0.95 }}
        className={`${className}  
         cursor-pointer ${activeTab === 0 && 'opacity-60 pointer-events-none'}`}
        onClick={onClick}
      >
        <Icon type={IconType.GreenBgArrow} classes="stroke" />
      </m.div>
    </LazyMotion>
  );
};
