import { Link } from 'gatsby';
import React from 'react';

const Pagination = ({ previousPagePath, numberOfPages, allWpCategory, nextPagePath }) => {
  return (
    <>
      {numberOfPages > 1 && (
        <div className="flex items-center justify-center mt-12 xl:mt-16">
          {previousPagePath && (
            <span>
              <Link to={previousPagePath}>
                <svg
                  className="rotate-180"
                  width="8"
                  height="9"
                  viewBox="0 0 8 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 9L7.60374 4.5L0 0Z" fill="black" />
                </svg>
              </Link>
            </span>
          )}

          <ul className="flex mx-2 md:mx-7">
            {[...Array(numberOfPages)].map((un, i) => {
              const num = i + 1;
              return (
                <li key={`pagi-${i}`} className="mx-2">
                  <Link
                    to={`${allWpCategory?.nodes[0]?.uri}${num > 1 ? num : ''}`}
                    className="px-3 pb-2  border-b border-black hover:border-blue border-opacity-[0.11] "
                    activeClassName=" px-3 pb-2 border-b !text-primary border-blue "
                  >
                    {num}
                  </Link>
                </li>
              );
            })}
          </ul>

          {nextPagePath && (
            <span>
              <Link to={nextPagePath}>
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L0 9L7.60374 4.5L0 0Z" fill="black" />
                </svg>
              </Link>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default Pagination;
