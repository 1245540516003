import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Layout from '~/components/global/Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import FacebookSVG from '~/assets/images/social/Facebook.svg';
import TwitterSVG from '~/assets/images/social/Twitter.svg';
import LinkedInSVG from '~/assets/images/social/Linkedin.svg';

import { Author } from '../flexible/Articles';
import Image from '../elements/Image';
import FlexibleContent from '../global/FlexibleContent';
import { ResourceGridInner } from '../resources/ResourceGrid';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Wysiwyg from '../resources/Wysiwyg';

export default function articlePage({
  data: { wpPage, wpPost, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  
  return (
    <Layout wp={wp}>
      <Seo post={wpPost} title={wpPost?.title} />

      <div className="p-2 mx-auto md:px-5 md:py-4">
        <div className="bg-[#F1F1F1] relative rounded-md">
          <div className="noisy">
            <div className="container relative flex flex-wrap items-center pt-24 pb-16 md:flex-nowrap md:pt-60 md:pb-44 ">
              <div className="relative w-full md:w-1/2 ">
                <div className="space-y-8 max-w-[545px] md:pr-6 xl:pr-3  ">
                  <div className="space-y-6">
                    <h2 className="text-h2">{wpPost.title}</h2>
                    <div
                      className="p-large opacity-60"
                      dangerouslySetInnerHTML={{
                        __html: wpPost?.postFlexibleContent?.masthead?.description,
                      }}
                    />
                  </div>
                  <div className="mt-[26px]">
                    <Author className="opacity-100" author={wpPost?.author?.node?.name} date={wpPost?.date} />
                  </div>
                </div>
              </div>
              <div className="w-full mt-12 md:w-1/2 md:mt-z0">
                <div className="relative flex-none aspect-w-588 aspect-h-365 image-absolute ">
                  <Image
                    objectFit="cover"
                    image={wpPost?.postFlexibleContent?.masthead?.image || wpPost?.featuredImage?.node}
                    className="max-w-[587px] max-h-[365px] absolute w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 md:mt-[87px]">
        <div className="container ">
          <div className="relative flex flex-wrap">
            <div className="flex-1 text-center md:w-2/12">
              <div className="sticky top-[7rem] flex flex-col w-full">
                <div className="font-bold text-[15px] leading-[1.65] mb-[10px] md:mb-[21px] ">Share</div>
                <ShareLinks />
              </div>
            </div>
            <div className="flex justify-center w-full md:w-10/12 article-content">
              {wpPost?.content && <Wysiwyg content={wpPost?.content} />}
              <FlexibleContent
                content={wpPost?.postFlexibleContent?.postFlexibleContent}
                prefix="Post_Postflexiblecontent_PostFlexibleContent_"
              />
            </div>
          </div>
        </div>
      </div>
      {wpPost?.postFlexibleContent?.relatedArticles?.articles && (
        <div className="bg-dark-purple">
          <div className="container py-[70px] md:py-[150px]">
            <div className="mx-auto">
              <h2 className="text-center text-h2">Related Articles </h2>
            </div>
            <ResourceGridInner posts={wpPost?.postFlexibleContent?.relatedArticles?.articles} />
          </div>
        </div>
      )}
    </Layout>
  );
}

export const ShareLinks = () => {
  const [shareURL, setShareURL] = useState(null);

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    url && setShareURL(url);
  }, []);

  return (
    <div className="flex mx-auto space-x-[10px] md:space-x-0 md:block mb-8 md:mb-0 md:space-y-[10px]">
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border group banner-safari-fix social focus:outline-none ">
        <FacebookShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none " />
        <img src={FacebookSVG} alt="facebook" draggable="false" />
      </div>
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border banner-safari-fix social focus:outline-none ">
        <TwitterShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none" />
        <img src={TwitterSVG} alt="twitter" draggable="false" />
      </div>
      <div className="relative flex items-center justify-center w-12 h-12 transition-colors duration-300 ease-in-out bg-white rounded-full shadow-md cursor-pointer hover:border banner-safari-fix social focus:outline-none ">
        <LinkedinShareButton url={shareURL} className="absolute top-0 left-0 z-10 w-full h-full focus:outline-none" />
        <img src={LinkedInSVG} alt="linkedin" draggable="false" />
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
      author {
        node {
          name
        }
      }
    }
    wp {
      ...GeneratedWp
    }
  }
`;
