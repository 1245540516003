import React, { useState } from 'react';
import { Section } from '~/components/elements/Section';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_Masthead } from 'src/_generated/types';
import { Icon, IconType } from '../elements/Icon';
import Image from '../elements/Image';
import { Link } from 'gatsby';
import Lottie from 'react-lottie-player';

import homepageMasthead from '~/assets/images/homepageMasthead.json';

import { backGroundSwitcher } from '../utils/backgroundSwitcher';
import { ContForm } from '../elements/forms/ContactForm';
import { SalesLink } from './Pricing';
import { motion } from 'framer-motion';
import { SplitText } from '../elements/animations/SplitText';

let gradientColour;

export default function Masthead({
  section,
  heading,
  content,
  link,
  layout,
  subheading,
  mediaOptions,
  productOptions,
  backgroundColour,
}: WpPage_Flexiblecontent_FlexibleContent_Masthead) {
  return (
    <Section {...section}>
      {layout === 'main' ? (
        <DefaultMasthead heading={heading} content={content} link={link} />
      ) : layout === 'product' ? (
        <ProductMasthead
          subheading={subheading}
          heading={heading}
          content={content}
          link={link}
          image={mediaOptions?.media?.image}
          productOptions={productOptions}
          backgroundColour={backgroundColour}
        />
      ) : layout === 'importer-pricing' ? (
        <ImporterPricingMasthead />
      ) : layout === 'flexible-pricing' ? (
        <FlexiblePricingMasthead />
      ) : layout === 'centered' ? (
        <CenteredMasthead subheading={subheading} heading={heading} content={content} link={link} />
      ) : (
        layout === 'form' && <FormMasthead subheading={subheading} heading={heading} content={content} />
      )}
    </Section>
  );
}

export const DefaultMasthead = ({ heading, content, link }) => {
  return (
    <div className="relative masthead-border">
      <div className="relative rounded-md default-blue-gradient">
        <div className="noisy">
          <div className="container max-w-[1600px] pt-[7rem] lg:pt-[8rem] lg:pb-[6rem] lg:flex items-center relative">
            <div className="relative w-full lg:w-1/3 ">
              <div className="space-y-8 text-center mx-auto lg:text-left   lg:max-w-[410px]">
                <div className="space-y-6">
                  {heading && (
                    <motion.h1 className="text-h1">
                      <SplitText
                        initial={{ y: '100%' }}
                        animate="visible"
                        variants={{
                          visible: (i) => ({
                            y: 0,
                            transition: {
                              delay: i * 0.4,
                            },
                          }),
                        }}
                      >
                        {heading}
                      </SplitText>
                    </motion.h1>
                  )}
                  {content && <div className="p-large" dangerouslySetInnerHTML={{ __html: content }} />}
                </div>
                <Button link={link} type={ButtonType.Button} color={ColorType.white} />
              </div>
            </div>

            <div className="w-full mt-12 lg:w-2/3 md:mt-0">
              <div className="xl:w-[110%]  default-masthead-lottie" style={{ aspectRatio: `896 / 562` }}>
                <Lottie loop animationData={homepageMasthead} play />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductMasthead = ({ subheading, heading, content, link, image, productOptions, backgroundColour }) => {
  gradientColour = backGroundSwitcher(backgroundColour);
  return (
    <div className="masthead-border ">
      <div className={`${gradientColour} rounded-md pb-[50px] md:pb-0`}>
        <div className="noisy">
          <div className="container">
            <div className="max-w-[1600px] mx-auto flex flex-wrap items-end pt-24 md:pt-[10rem] relative">
              <div className="relative md:pb-[20px] lg:pb-[100px] w-full xl:w-1/2 content-container">
                <div className="max-w-[692px]">
                  <MastheadContent
                    subheading={subheading}
                    heading={heading}
                    content={content}
                    link={link}
                    contentClass="max-w-[477px]"
                  />
                </div>
                <div className="mt-[1.5rem] xl:mt-[90px] max-w-max">
                  <MastHeadPricing {...productOptions} />
                </div>
              </div>
              <div className="xl:absolute product-masthead-image w-full lg:max-w-[960px] h-auto mb-[-128px] bottom-0 right-0  z-10 ">
                <Image image={image} className="w-full h-full max-w-[1000px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CenteredMasthead = ({ subheading, heading, content, link }) => (
  <div className="masthead-border">
    <div className="rounded-md default-blue-gradient">
      <div className="relative flex noisy items-center pt-32 pb-16 md:pt-[10rem] md:pb-[157px]">
        <div className="relative flex justify-center w-full px-4 md:px-16 lg:px-24">
          <div className="space-y-8 max-w-[618px] text-center mx-auto">
            <div className="space-y-6">
              {subheading && <h6 className="text-h6">{subheading}</h6>}
              {heading && <h1 className="text-h1">{heading}</h1>}
              {content && (
                <div className="p-large max-w-[555px] mx-auto" dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </div>
            <div className="flex justify-center">
              <Button link={link} type={ButtonType.Button} color={ColorType.white} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const FormMasthead = ({ subheading, heading, content }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [submitted, setIsSubmitted] = useState(false);

  const Menu = [{ title: 'Sales' }, { title: 'Support' }, { title: 'Other' }];

  return (
    <div className="masthead-border">
      <div className="rounded-md bg-light-grey">
        <div className="relative flex flex-col items-center pt-32 pb-16 md:pt-[10rem] md:pb-[157px] noisy">
          <div className="relative flex justify-center w-full px-4 text-center md:px-16 lg:px-24">
            <MastheadContent
              subheading={subheading}
              heading={heading}
              content={content}
              contentClass="max-w-[641px] mx-auto"
            />
          </div>
          <div className="container">
            <div className="mt-[69px] relative w-full mx-auto">
              <div className="bg-white rounded-[5px] max-w-[792px] w-full mx-auto ">
                <div className="px-6 md:px-[102px] pt-[53px] pb-[75px]">
                  <div className="flex justify-center">
                    <div className="flex items-center justify-center space-x-[6px] md:space-x-[30px] ">
                      {submitted && (
                        <div className="text-center">
                          <h2 className="text-h2">Thank you for getting in touch!</h2>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    {!submitted ? (
                      <ContForm setIsSubmitted={setIsSubmitted} />
                    ) : (
                      <div className="mt-6 text-center p-large">Someone will be in contact shortly</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MastheadContent = ({ subheading, heading, content, link, contentClass }) => {
  return (
    <div className="space-y-8 ">
      <div className="space-y-6">
        {subheading && <h6 className="text-h6">{subheading}</h6>}
        {heading && <h1 className="text-h1">{heading}</h1>}
        {content && <div className={`p-large ${contentClass}`} dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
      {link && <Button link={link} type={ButtonType.Button} color={ColorType.white} />}
    </div>
  );
};

export const MastHeadPricing = ({ link, price, priceHeading, priceSuffix }) => {
  return (
    <>
      <h6 className="!opacity-100 text-h6">{priceHeading}</h6>
      <div className="mt-[15px] rounded-[2px] border border-black border-opacity-[0.28]">
        <div className="pt-[15px] pb-[12px] px-[19px] tracking-[-0.005em]">
          <span className="text-[27px] md:text-[35px] leading-[0.91] ">{price}</span>{' '}
          <span className="text-[18px] md:text-[20px]">/ {priceSuffix}</span>
        </div>
      </div>
      <div className="mt-[18px]">
        <SalesLink title={link?.title} url={link?.url} iconClass="mt-[1px] !ml-[8px]" />
      </div>
    </>
  );
};

export const MastheadPricingLink = ({ link }) => (
  <Link to={link?.url} className="relative flex items-center max-w-max group">
    <div className="flex items-center ">
      <span className="mr-[5px]  ">{link?.title}</span>
      <div className=" mt-[1px] group-hover:invert  ">
        <Icon type={IconType.CircleBgArrow} />
      </div>
    </div>
  </Link>
);
