import React, { useLayoutEffect, useRef } from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_OneColumn } from 'src/_generated/types';
import Marquee from 'react-fast-marquee';

import tools from '../../assets/images/tools.png';
import redirects from '../../assets/images/redirects.svg';
import { ReactSVG } from 'react-svg';
import ScrollMove from '../elements/animations/ScrollMove';

export default function OneColumn({
  section,
  column,
  image,
  layout,
  images,
  tools,
}: WpPage_Flexiblecontent_FlexibleContent_OneColumn) {
  return (
    <Section {...section}>
      {layout === 'tools' ? (
        <ToolsLayout column={column} tools={tools} />
      ) : layout === 'surround' ? (
        <SurroundLayout column={column} images={images} />
      ) : (
        <div className="container">
          <div className="flex justify-center text-center ">
            <div className="max-w-[582px] ">
              <Content {...column} />
            </div>
          </div>

          <Image image={image} className="max-w-[1057px] mx-auto" />
        </div>
      )}
    </Section>
  );
}

export const ToolsLayout = ({ column, tools }) => {
  return (
    <>
      <div className={`container  `}>
        <div className={`max-w-[648px] text-center md:text-left`}>
          <Content headingClass="max-w-[485px] mx-auto md:mx-0" {...column} />
        </div>
      </div>

      <div className="mt-[79px] tools-marquee ">
        <Marquee gradient={false} className="overflow-visible py-[20px] !overflow-x-visible">
          {tools?.topRow?.map((topTool, topToolIndex) => (
            <MarqueeItem key={`topToopl${topToolIndex}`} tool={topTool?.tool} />
          ))}
        </Marquee>
        <Marquee direction="right" gradient={false} className="overflow-visible py-[20px] !overflow-x-visible">
          {tools?.bottomRow?.map((bottomTool, bottomToolIndex) => (
            <MarqueeItem key={`topToopl${bottomToolIndex}`} tool={bottomTool?.tool} />
          ))}
        </Marquee>
      </div>
    </>
  );
};

export const MarqueeItem = ({ tool = 'Title' }) => {
  return (
    <div className="bg-white rounded-[7px] border border-black border-opacity-[0.11] marquee-tools-item w-full min-w-[250px] md:min-w-[381px] mr-[37px]">
      <div className="px-[32px] pt-[29px] pb-[27px]">
        <div className="flex items-center space-x-[14px]">
          <div className="h-[32px] w-[32px] bg-dark-blue bg-opacity-20 rounded-[5px]"></div>
          <h5 className="text-[17px] font-bold leading-[1.19] tracking-[-0.005em] ">{tool}</h5>
        </div>
        <div className="mt-[14px] bg-[#ECECEC] w-full max-w-[317px] h-[10px]"></div>
        <div className="mt-[11px] bg-[#ECECEC] w-full max-w-[125px] h-[10px]"></div>
      </div>
    </div>
  );
};

export const SurroundLayout = ({ column, images }) => {
  return (
    <div className="container relative ">
      <div className="max-w-[1000px]  purple-gradient  relative   mx-auto  md:max-h-[440px] rounded-[10px] ">
        <div className="pt-[70px] md:py-[130px] px-6 flex flex-col items-center justify-center w-full text-center noisy ">
          <Content {...column} contentClass="max-w-[582px]" />
          <ReactSVG
            src={redirects}
            beforeInjection={(svg) => {
              svg.setAttribute('height', '100%');
              svg.setAttribute('width', '100%');
            }}
            className={`w-full md:hidden`}
          />
          {/* <Image image={images[0]?.image} /> */}
        </div>
      </div>

      <div className="hidden md:block absolute md:top-[-105px] max-w-[315px] w-full lg:top-[-73px] md:left-[-26px] lg:left-[-7px] xl:left-[104px]">
        <ScrollMove offset={50}>
          <Image image={images[0]?.image} className="max-w-[315px] w-full h-full" />
        </ScrollMove>
      </div>
      <div className="hidden md:block absolute top-[-109px] md:right-[93px] lg:right-[283px] max-w-[315px] w-full">
        <ScrollMove offset={10}>
          <Image image={images[1]?.image} className="max-w-[315px] w-full h-full" />
        </ScrollMove>
      </div>
      <div className="hidden md:block absolute bottom-[-98px] left-[85px] xl:left-[270px] max-w-[315px] w-full">
        <ScrollMove offset={20}>
          <Image image={images[2]?.image} className="max-w-[315px] w-full h-full" />
        </ScrollMove>
      </div>
      <div className="hidden md:block absolute lg:bottom-[-25px] md:bottom-[-77px] md:right-[-35px] max-w-[315px] w-full  lg:right-[-10px] xl:right-[111px]">
        <ScrollMove offset={50}>
          <Image image={images[3]?.image} className="max-w-[315px] w-full h-full" />
        </ScrollMove>
      </div>
    </div>
  );
};

export const Content = ({ heading, content, headingClass, contentClass }) => {
  return (
    <div className="space-y-[20px]">
      <h2 className={`text-h2 ${headingClass}`}>{heading}</h2>
      <div className={`${contentClass} p-large opacity-60`} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};
