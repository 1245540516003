import React from 'react';
import { useField } from 'formik';

export default function MultiOptionSelect(props) {
  const { label, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const labelClassName = props.labelClassName;
  const optionContainerClassName = props.optionContainerClassName;
  const optionClassName = props.optionClassName;

  return (
    <label>
      {/* <div className={`text-13px font-extrabold tracking-none uppercase font-heading mb-2 ${labelClassName}`}>
        {label}
      </div> */}
      <div className={`grid grid-cols-3 gap-4 text-center ${optionContainerClassName}`}>
        {options.map((option) => (
          <div
            key={option.value}
            onClick={() => helpers.setValue(option.value)}
            className={`cursor-pointer select-none text-white font-body bg-black rounded px-5 md:px-[28px] py-[6px] font-semibold text-[14px] md:text-[16px] transition-colors duration-200  hover:bg-yellow hover:text-black  bg-opacity-[0.25] ${optionClassName} ${
              meta.value === option.value
                ? ' bg-yellow text-black !bg-opacity-100 '
                : isInvalid
                ? 'border-[#ff0000]'
                : 'border-black'
            }`}
            dangerouslySetInnerHTML={{ __html: option.label }}
          />
        ))}
      </div>

      <div className="visually-hidden-field">
        <input type="text" name="numberEmployees" value={meta.value} />
      </div>
    </label>
  );
}

export const FormTab = ({ children, i, activeTab }) => (
  <div
    className={`text-white cursor-pointer bg-black rounded ${
      i === activeTab && 'bg-yellow text-black !bg-opacity-100 '
    } hover:bg-yellow hover:bg-opacity-100 hover:text-black transition-colors duration-200 bg-opacity-[0.25]`}
  >
    <div className=" px-5 md:px-[28px] py-[6px] font-semibold text-[14px] md:text-[16px]">{children}</div>
  </div>
);
