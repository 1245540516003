import React from 'react';

export const ArrowRight = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.00049L15 7.00049" stroke="currentColor" strokeWidth="1.75" />
    <path d="M9.54541 12.4546L15 7.00004L9.54541 1.5455" stroke="currentColor" strokeWidth="1.75" />
  </svg>
);

export const ArrowDown = () => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0L5 11" stroke="currentColor" strokeWidth="1.25" />
    <path d="M1 7L5 11L9 7" stroke="currentColor" strokeWidth="1.25" />
  </svg>
);

export const CircleBgArrow = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5" cy="11.5" r="11.5" fill="black" />
    <path d="M6.81494 11.0742L16.1853 11.0742" stroke="white" strokeWidth="1.06481" />
    <path d="M12.7778 14.4814L16.1852 11.074L12.7778 7.66663" stroke="white" strokeWidth="1.06481" />
  </svg>
);

export const CircleBgArrowLarge = () => (
  <svg preserveAspectRatio="none" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28.5" cy="28.5" r="28.5" fill="black" />
    <path d="M16.8892 27.4448L40.1114 27.4448" stroke="white" strokeWidth="2.63889" />
    <path d="M31.667 35.8892L40.1114 27.4447L31.667 19.0003" stroke="white" strokeWidth="2.63889" />
  </svg>
);

export const GreenBgArrow = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="15" cy="15" rx="15" ry="15" transform="rotate(180 15 15)" fill="#82EBBE" />
    <path d="M20.9922 15.4443L8.77451 15.4443" stroke="#030135" strokeWidth="1.38889" />
    <path d="M13.2173 11L8.77449 15.4444L13.2173 19.8889" stroke="#030135" strokeWidth="1.38889" />
  </svg>
);

export const Cross = () => (
  <svg
    className="cross-svg"
    preserveAspectRatio="none"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9403 2.57976C16.8063 2.27075 16.5279 2.02605 16.0712 1.94802C9.83796 0.877521 3.62283 3.90446 2.25582 10.3917C1.54812 13.7508 1.72954 17.686 3.66911 20.6074C5.56332 23.4613 8.89415 24.9507 12.1803 25.4315C15.3793 25.8998 19.1887 25.7975 21.8644 23.7609C24.3535 21.8667 25.2289 18.7343 25.426 15.7433C25.7962 10.0954 22.8568 3.19987 16.9399 2.57968L16.9403 2.57976ZM22.7369 16.1357C22.5924 18.341 21.774 20.6614 19.8692 21.9239C17.7134 23.3541 14.3839 23.1442 11.9644 22.716C9.49331 22.2783 7.15087 21.0475 5.74174 18.9255C4.21348 16.622 4.36641 13.2609 4.91276 10.6694C5.80713 6.42743 9.77276 4.2623 13.8122 4.36544C14.059 4.90122 14.6096 5.32415 15.3415 5.21129C17.8011 4.82843 20.0128 6.43261 21.2605 8.4197C22.6695 10.6609 22.9069 13.557 22.7371 16.1358L22.7369 16.1357Z"
      fill="#264BB3"
    />
    <path
      d="M18.7997 18.5343C16.9941 17.3014 15.6305 15.5389 14.4522 13.6344C15.7199 12.9214 17.0509 12.3255 18.4169 11.8277C20.0158 11.2434 19.326 8.67003 17.7092 9.26057C16.1261 9.83853 14.5988 10.5273 13.1286 11.3394C12.5654 10.31 12.0254 9.28377 11.4738 8.31563C10.6237 6.82431 8.32345 8.16586 9.1746 9.65931C9.74203 10.6549 10.2968 11.6801 10.8695 12.699C9.58592 13.5375 8.32345 14.5373 7.64432 15.8642C6.86386 17.3892 9.16097 18.7372 9.94353 17.2079C10.416 16.285 11.2904 15.5826 12.2122 14.9814C13.618 17.2342 15.2424 19.3203 17.4572 20.8329C18.8778 21.8053 20.2079 19.4966 18.7998 18.5347L18.7997 18.5343Z"
      fill="#264BB3"
    />
  </svg>
);

export const Tick = () => (
  <svg
    className="tick-svg"
    preserveAspectRatio="none"
    viewBox="0 0 27 27"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7851 3.27152L16.8358 3.06873L16.8354 3.06865L16.911 2.59243L16.4815 2.77861C16.4187 2.63367 16.2872 2.49217 15.987 2.44088L15.9866 2.4408C9.94585 1.40337 4.04266 4.33703 2.74508 10.4948C2.0525 13.7821 2.24817 17.5632 4.08566 20.3309L4.0857 20.3309C5.87358 23.0246 9.04816 24.4679 12.2527 24.9368L12.2527 24.9368C15.4184 25.4002 19.0527 25.2727 21.5616 23.3631L21.5616 23.363C23.8774 21.6007 24.7333 18.6511 24.9271 15.7106L17.7851 3.27152ZM17.7851 3.27152L17.7636 3.22207M17.7851 3.27152L17.7636 3.22207M17.7636 3.22207C20.0891 3.75399 21.8827 5.37564 23.1061 7.52356C24.4819 9.93892 25.1063 12.9759 24.9271 15.7104L17.7636 3.22207ZM15.3415 5.21129L15.2646 4.71724C17.9986 4.29167 20.3787 6.07515 21.6838 8.15357C23.1772 10.5289 23.4081 13.5542 23.236 16.1686L23.179 17.0341L23.1484 17.0136C22.8595 19.0458 21.9796 21.1249 20.1456 22.3406C18.9636 23.1247 17.4916 23.441 16.0416 23.5162C14.5862 23.5916 13.1084 23.4262 11.8773 23.2083L11.8772 23.2083C9.30898 22.7534 6.82877 21.4663 5.32521 19.2021L5.3251 19.2019C3.67875 16.7205 3.87202 13.1821 4.42351 10.5662L4.91276 10.6694L4.42351 10.5662C5.38231 6.01872 9.62327 3.75832 13.825 3.8656L14.1361 3.87354L14.2664 4.15625C14.4396 4.53229 14.7974 4.78928 15.2653 4.71713L15.3415 5.21129ZM15.3415 5.21129C17.8011 4.82843 20.0128 6.43261 21.2605 8.4197L15.3415 5.21129Z"
      fill="#82EBBE"
      stroke="#82EBBE"
    />
    <path
      d="M10.5158 16.5722C12.328 13.8483 14.788 11.7722 17.248 9.6844C17.5841 9.3966 17.9202 9.0966 18.3758 9.0244C18.8919 8.94003 19.348 9.06003 19.5641 9.61221C19.7919 10.2 19.3963 10.3444 18.9519 10.5483C17.8958 11.0405 17.1158 11.9283 16.2758 12.6961C14.8719 13.9799 13.4197 15.2161 12.3758 16.8238C11.968 17.4599 11.548 18.0838 11.3919 18.8399C11.3319 19.1639 11.248 19.4639 10.888 19.536C10.468 19.6321 10.228 19.3321 10.0241 19.0439C9.48406 18.2278 9.06406 17.3516 8.70406 16.4516C8.46406 15.8394 8.27187 15.2039 8.05625 14.5794C7.97235 14.3516 7.96016 14.1233 8.17625 13.9916C8.45235 13.8116 8.70406 13.9555 8.86016 14.1838C9.08797 14.5077 9.28016 14.8559 9.46016 15.2038C9.72406 15.7077 9.89188 16.272 10.5158 16.572L10.5158 16.5722Z"
      fill="#82EBBE"
      stroke="#82EBBE"
    />
  </svg>
);

export const JobArrow = () => (
  <svg preserveAspectRatio="none" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12.3734L30 12.3734" stroke="white" strokeWidth="2.75" />
    <path d="M19.0898 23.2812L29.9989 12.3722L19.0898 1.46307" stroke="white" strokeWidth="2.75" />
  </svg>
);

export const Star = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 18.4549C0 18.7262 0.180873 18.8166 0.361752 18.8166C9.677 20.6254 15.5556 25.7805 17.1835 35.6384C17.1835 35.8192 17.3643 36.0001 17.5452 36.0001C17.7261 36.0001 17.8165 35.8192 17.8165 35.6384C19.4444 25.7805 25.323 20.6254 34.6382 18.8166C34.8191 18.8166 35 18.6358 35 18.4549C35 18.274 34.8191 18.0931 34.6382 18.0931C25.323 16.2843 19.4444 11.1293 17.8165 1.27143C17.8165 1.09055 17.6357 0.909668 17.4548 0.909668C17.2739 0.909668 17.1835 1.09055 17.1835 1.27143C15.5556 11.1293 9.677 16.2843 0.361752 18.0931C0.180873 18.0931 0 18.1836 0 18.4549Z"
      fill="#3369FB"
    />
  </svg>
);

export const CircleDownArrow = () => (
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11.5" cy="11.8481" r="11" transform="rotate(90 11.5 11.8481)" stroke="white" strokeOpacity="0.29" />
    <path d="M11.9258 7.16309L11.9258 16.5335" stroke="white" strokeWidth="1.06481" />
    <path d="M8.51758 13.126L11.925 16.5334L15.3324 13.126" stroke="white" strokeWidth="1.06481" />
  </svg>
);

export const LinkedIn = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16.949" height="16.949" transform="translate(0.40625 0.406738)" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6604 0.406738H2.10115C1.16508 0.406738 0.40625 1.16557 0.40625 2.10164V15.6609C0.40625 16.5969 1.16508 17.3558 2.10115 17.3558H15.6604C16.5964 17.3558 17.3553 16.5969 17.3553 15.6609V2.10164C17.3553 1.16557 16.5964 0.406738 15.6604 0.406738ZM3.79605 4.27454C3.79605 5.00627 4.38954 5.60076 5.12348 5.60076C5.85511 5.60076 6.45013 5.00627 6.45013 4.27454C6.45013 3.54358 5.85511 2.94909 5.12348 2.94909C4.39031 2.94909 3.79605 3.54281 3.79605 4.27454ZM4.00829 13.966H6.28498V6.64708H4.00829V13.966ZM14.8129 13.966H12.5378V10.4068C12.5378 9.55782 12.5223 8.46601 11.3547 8.46601C10.17 8.46601 9.98788 9.3905 9.98788 10.3451V13.966H7.71273V6.64708H9.89681V7.64714H9.92768C10.2318 7.07193 10.9742 6.46511 12.0817 6.46511C14.3869 6.46511 14.8129 7.98101 14.8129 9.95183V13.966Z"
      fill="#030135"
    />
  </svg>
);

export const Facebook = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16.949" height="16.949" transform="translate(0.40625 0.253906)" fill="white" />
    <path
      d="M15.6604 0.253906H2.10115C1.16508 0.253906 0.40625 1.01274 0.40625 1.94881V15.508C0.40625 16.4441 1.16508 17.2029 2.10115 17.2029H8.88076V10.8221H6.33841V8.42932H8.88076V7.14438C8.88076 4.7117 10.1432 3.64371 12.207 3.64371C13.176 3.64371 13.6973 3.71271 13.9506 3.74623L13.9655 3.7482V6.03651H12.5579C11.6819 6.03651 11.4231 6.48955 11.4231 7.40679V8.42932H13.9435L13.595 10.8221H11.4231V17.2029H15.6604C16.5964 17.2029 17.3553 16.4441 17.3553 15.508V1.94881C17.3553 1.01274 16.5964 0.253906 15.6604 0.253906Z"
      fill="#030135"
    />
  </svg>
);

export const Twitter = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16.949" height="16.949" transform="translate(0.40625 0.254395)" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6604 0.254395H2.10115C1.16508s 0.254395 0.40625 1.01323 0.40625 1.9493V15.5085C0.40625 16.4446 1.16508 17.2034 2.10115 17.2034H15.6604C16.5964 17.2034 17.3553 16.4446 17.3553 15.5085V1.9493C17.3553 1.01323 16.5964 0.254395 15.6604 0.254395ZM13.4148 5.54195C13.9072 5.48504 14.3762 5.35885 14.8129 5.17135C14.4867 5.64298 14.0737 6.05726 13.5978 6.38948C13.6023 6.49051 13.6046 6.59199 13.6046 6.6939C13.6046 9.80387 11.157 13.3899 6.67979 13.3899C5.30495 13.3899 4.02593 13.0003 2.9486 12.3328C3.13934 12.3548 3.33327 12.3659 3.52948 12.3659C4.67023 12.3659 5.71973 11.9895 6.55248 11.3582C5.48747 11.3392 4.58855 10.6585 4.27872 9.72357C4.42702 9.75049 4.57988 9.76549 4.7364 9.76549C4.95861 9.76549 5.17399 9.73681 5.37796 9.68298C4.26412 9.46724 3.42544 8.51603 3.42544 7.37555V7.34599C3.75352 7.52202 4.12906 7.62791 4.52786 7.64026C3.8749 7.21848 3.44552 6.49801 3.44552 5.68181C3.44552 5.24988 3.56552 4.84575 3.77451 4.49809C4.9755 5.92226 6.76877 6.85935 8.79155 6.9573C8.75049 6.78567 8.72858 6.60566 8.72858 6.42125C8.72858 5.12193 9.81823 4.06792 11.1625 4.06792C11.8625 4.06792 12.4949 4.35382 12.9389 4.81133C13.4937 4.70545 14.0148 4.50956 14.4848 4.23999C14.3032 4.78972 13.9177 5.25076 13.4148 5.54195Z"
      fill="#030135"
    />
  </svg>
);

export const Clock = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0C5.51114 0 3.60295 0.790376 2.19655 2.19655C0.790302 3.60281 0 5.51095 0 7.5C0 9.48905 0.790376 11.397 2.19655 12.8034C3.60281 14.2097 5.51095 15 7.5 15C9.48905 15 11.397 14.2096 12.8034 12.8034C14.2097 11.3972 15 9.48905 15 7.5C14.9978 5.51169 14.2067 3.60498 12.8005 2.19951C11.395 0.793255 9.48831 0.00221538 7.5 0V0ZM10.7928 10.7928C10.5671 11.0178 10.2022 11.0178 9.97645 10.7928L7.09183 7.90818C6.98366 7.80001 6.92308 7.6529 6.92308 7.50002V2.30771C6.92308 1.98895 7.18125 1.73079 7.50001 1.73079C7.81876 1.73079 8.07693 1.98895 8.07693 2.30771V7.26131L10.7928 9.97719V9.97647C11.0178 10.2022 11.0178 10.5671 10.7928 10.7928L10.7928 10.7928Z"
      fill="#264BB3"
    />
  </svg>
);

export const NavArrow = () => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.5" cy="21.5" r="21" fill="white" stroke="white" />
    <path
      d="M27.0907 21.4663C27.2153 21.5965 27.289 21.7816 27.2996 21.9851L27.1162 21.9947L27.3 22.0007L27.3 22.0007L27.0852 22.0063L27.2994 22.0203C27.2992 22.0233 27.299 22.0265 27.2987 22.0298C27.2955 22.0812 27.2901 22.1652 27.2631 22.2525C27.2317 22.3539 27.1762 22.4476 27.0896 22.5363L22.8061 27.0594C22.806 27.0595 22.8059 27.0596 22.8058 27.0597L22.8057 27.0598L22.5878 26.8535C22.4167 27.035 22.0959 27.0554 21.9145 26.8721L27.0907 21.4663ZM27.0907 21.4663C27.0905 21.466 27.0902 21.4657 27.0899 21.4654M27.0907 21.4663L27.0899 21.4654M24.7186 22.777L25.1314 22.777L21.8959 26.1986L25.1314 21.2243L24.7186 21.2243M24.7186 22.777L25.0022 22.477L17.4762 22.477C17.213 22.477 17 22.264 17 22.0007C17 21.7374 17.213 21.5243 17.4762 21.5243L25.0022 21.5243L24.7186 21.2243M24.7186 22.777L17.4762 22.777C17.0472 22.777 16.7 22.4296 16.7 22.0007C16.7 21.5718 17.0472 21.2243 17.4762 21.2243L24.7186 21.2243M24.7186 22.777L27.0899 21.4654M24.7186 21.2243L21.6779 18.0089L21.6777 18.0087C21.3936 17.7075 21.406 17.1967 21.7086 16.9111C22.0061 16.6302 22.4866 16.6208 22.8005 16.9362L22.8057 16.9415L22.8057 16.9416L27.0899 21.4654"
      fill="black"
      stroke="#4E5A6D"
      strokeWidth="0.6"
    />
  </svg>
);

export const DropDown = () => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 8L0.669872 0.500001L9.33013 0.5L5 8Z" fill="black" />
  </svg>
);

export enum IconType {
  None = 'none',
  ArrowRight = 'arrowRight',
  ArrowDown = 'arrowDown',
  CircleBgArrow = 'circleBgArrow',
  CircleBgArrowLarge = 'circleBgArrowLarge',
  GreenBgArrow = 'greenBgArrow',
  Cross = 'cross',
  Tick = 'tick',
  JobArrow = 'jobArrow',
  Star = 'star',
  CircleDownArrow = 'circleDownArrow',
  LinkedIn = 'linkedIn',
  Facebook = 'facebook',
  Twitter = 'twitter',
  NavArrow = 'navArrow',
  Clock = 'clock',
  DropDown = 'dropDown',
}

const IconMap = {
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  circleBgArrow: CircleBgArrow,
  circleBgArrowLarge: CircleBgArrowLarge,
  greenBgArrow: GreenBgArrow,
  cross: Cross,
  tick: Tick,
  jobArrow: JobArrow,
  star: Star,
  circleDownArrow: CircleDownArrow,
  linkedIn: LinkedIn,
  facebook: Facebook,
  twitter: Twitter,
  navArrow: NavArrow,
  clock: Clock,
  dropDown: DropDown,
};

interface IconProps {
  type: string;
  fill: string;
  classes: string;
}

export const Icon = ({ type, classes = '' }: IconProps) => {
  if (type === IconType.None || !type) return null;
  return <div className={classes}>{IconMap[type]()}</div>;
};
