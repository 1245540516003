import React from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_Benefits } from 'src/_generated/types';

export default function Benefits({ section, heading, image, items }: WpPage_Flexiblecontent_FlexibleContent_Benefits) {
  return (
    <Section {...section}>
      <div className="container">
        <div className="flex md:space-x-[45px] items-center flex-wrap md:flex-nowrap">
          <div className="w-full md:w-1/2 lg:pr-[40px]">
            <Image image={image} />
          </div>

          <div className="w-full mt-12 md:w-1/2 md:mt-0">
            <h3 className="text-h3">{heading}</h3>

            <div
              className="mt-6 ml-[-20px] md:mt-[60px] flex  xl:ml-[-85px] flex-wrap gap-y-[30px]
             md:gap-y-[41px] "
            >
              {items?.map((item, i) => (
                <div key={`bene${i}`} className="pl-[20px] xl:pl-[85px] w-full sm:w-1/2">
                  <Benefit {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export const Benefit = ({ heading, content }) => (
  <>
    <h5 className="text-h5">{heading}</h5>
    <div className="mt-[5px] md:mt-[15px] opacity-60" dangerouslySetInnerHTML={{ __html: content }} />
  </>
);
