import React from 'react';
import { useField } from 'formik';

export default function CheckboxField(props) {
  const { label, name, reverse, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const className = props.className;
  const labelClassName = props.labelClassName;
  const innerClassName = props.innerClassName;

  return (
    <div className={`mt-5 leading-normal select-none text-12px opacity-90 w-full ${className} `}>
      <div onClick={() => helpers.setValue(!!!meta.value)} className="inline-flex w-full cursor-pointer group">
        <div className={`flex items-baseline -ml-4 w-full ${innerClassName} ${reverse && 'flex-row-reverse'}`}>
          <div className="relative w-6 pl-4">
            <button
              className={`h-3 w-3 absolute top-[-0.70rem] left-[1.05rem] border   opacity-100 transition-opacity duration-200 group-hover:opacity-50  ${
                meta.value === true ? `bg-black` : isInvalid ? 'border-[#ff0000]' : ''
              } `}
              type="button"
              role="checkbox"
            ></button>
          </div>

          <div className={`pl-4 ${labelClassName}`}>{label}</div>
        </div>
      </div>
    </div>
  );
}
