import React from 'react';
import { Section } from '~/components/elements/Section';
import { WpPage_Flexiblecontent_FlexibleContent_Jobs } from 'src/_generated/types';
import { Icon, IconType } from '../elements/Icon';
import { Button, ButtonType, ColorType } from '../elements/buttons/Button';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import useJobs from '~/hooks/useJobs';

export default function Jobs({
  section,
  heading,
  content,
  footerHeading,
  footerLink,
  footerContent,
  jobs,
}: WpPage_Flexiblecontent_FlexibleContent_Jobs) {
  const { allWorkableJob } = useJobs();

  return (
    <Section {...section} style={{ scrollMarginTop: '150px' }}>
      <div className={`container  `}>
        <div className="flex justify-center text-center">
          <div>
            <h2 className="text-h2">{heading}</h2>
            <div
              className="mt-[27px] max-auto max-w-[611px] p-large opacity-70"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
        <div className="mt-[40px] md:mt-[60px] space-y-[17px] md:space-y-[27px]">
          {allWorkableJob.nodes?.map((job, i) => (
            <JobListing key={`job${i}`} {...job} />
          ))}
        </div>

        <div className="mt-[114px] flex justify-center flex-col text-center">
          <h3 className="text-h3">{footerHeading}</h3>
          <div className="mt-[10px] p-large opacity-70" dangerouslySetInnerHTML={{ __html: footerContent }} />
          <div className="mt-[28px] flex justify-center">
            <Button link={footerLink} type={ButtonType.Button} color={ColorType.Blue} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export const JobListing = ({ location, title, application_url, url }) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div whileHover="hover">
        {' '}
        <a href={url} target="_blank">
          <div className="max-w-[996px] cursor-pointer bg-white bg-opacity-[0.10] group hover:bg-opacity-100 duration-200  transition duration-150 hover:bg-aqua w-full mx-auto rounded-[10px]">
            <div className="px-[22px] py-[25px] group md:px-[50px] md:py-[39px] w-full flex items-center justify-center ">
              <div className="w-full text-white group-hover:text-dark-purple">
                <h4 className="text-h4">{title}</h4>
                <div className=" p-large">
                  {location?.country} {location?.telecommuting === true && <span> - Remote</span>}
                </div>
              </div>
              <div className="flex justify-end mx-auto md:mx-0">
                <m.div variants={{ hover: { rotate: -45, y: -40, x: 20 } }}>
                  <Icon type={IconType.JobArrow} classes={`group-hover:invert w-[18px] md:w-[21px] `} />
                </m.div>
              </div>
            </div>
          </div>
        </a>
      </m.div>
    </LazyMotion>
  );
};
