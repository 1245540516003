import { graphql, useStaticQuery } from 'gatsby';

const useJobs = () => {
  const data = useStaticQuery(graphql`
    query {
      allWorkableJob(limit: 10) {
        nodes {
          title
          location {
            country
            location_str
            telecommuting
          }
          full_title
          application_url
          id
          url
        }
      }
    }
  `);

  return data;
};

export default useJobs;
