import React, { Fragment, useState } from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_Plans } from 'src/_generated/types';
import { Icon, IconType } from '../elements/Icon';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { AnimatePresence, motion } from 'framer-motion';

export default function Plans({
  section,
  heading,
  content,
  plans,
  bottomText,
}: WpPage_Flexiblecontent_FlexibleContent_Plans) {

  const plan = plans?.plan[0]?.pricingTable;

  const columnWithTags = [];

  plan.plans.map((plan, planIndex) => {
    if (plan.tag) {
      columnWithTags.push(planIndex);
    }
  });

  return (
    <Section {...section}>
      <div className={`container  `}>
        <div className="">
          <div className="flex justify-center text-center md:text-left md:justify-between mb-[70px] md:mb-0">
            <div>
              <h3 className="text-h3">{heading}</h3>
              <div
                className="mt-[9px] text-[14px] leading-[2.29] tracking-[-0.005em]  "
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>

          <div className="main mt-[-119px] hidden md:block">
            <span className="bg-white"></span>
            <span className="bg-green"></span>
            <table className="w-full price-table">
              <tbody>
                <tr>
                  <td className="white-left">&nbsp;</td>
                  {plan?.plans?.map((planHead, i) => {
                    return (
                      <td
                        className={`relative text-center pt-[32px] max-w-[186px] w-[186px] ${
                          columnWithTags.includes(i) && 'activeColumn'
                        }`}
                        key={`head${i}`}
                      >
                        {planHead?.tag && (
                          <div className="absolute top-[-21%] left-0 right-0 py-[9px] bg-aqua text-[12px]  uppercase font-semibold tracking-[-0.005em]  ">
                            {planHead?.tag}
                          </div>
                        )}
                        <div className="text-h4 font-heading">{planHead?.name}</div>
                        <div className="mt-[30px] text-[23.8px] leading-[0.64] opacity-60  ">{planHead?.price}</div>
                        <div className="mt-[7px] text-[12px] leading-[1.27] opacity-60 tracking-[-0.005em]">
                          {planHead?.priceSuffix}{' '}
                        </div>
                      </td>
                    );
                  })}
                </tr>

                {plan?.featureSections?.map((featSection, i) => (
                  <Fragment key={`featSection${i}`}>
                    <tr>
                      <td className="mt-[40px] py-[20px] uppercase text-[16px] opacity-[0.39] font-semibold mb-[25px]  ">
                        {featSection?.sectionName}
                      </td>
                      {featSection?.attributes?.map((attr, i) => (
                        <td className={`py-[20px] ${columnWithTags.includes(i) && 'activeColumn'}`} key={`blank${i}`}>
                          &nbsp;
                        </td>
                      ))}
                    </tr>

                    {featSection?.attributes?.map((attr, featSectionIndex) => (
                      <tr
                        className="border-b border-black border-opacity-[0.08]"
                        key={`featSectionAttr${featSectionIndex}`}
                      >
                        <td className={`py-[20px] font-medium text-[14px]  `}>{attr?.attributeName}</td>

                        {attr?.values?.map((value, attributeIndex) => (
                          <td
                            className={`text-center ${columnWithTags.includes(attributeIndex) && 'activeColumn'}`}
                            key={`value${attributeIndex}`}
                          >
                            {value?.boolean === true ? (
                              <Icon classes="flex justify-center w-[18px] h-[18px] mx-auto" type={IconType.Tick} />
                            ) : (
                              <Icon classes="flex justify-center w-[18px] h-[18px] mx-auto" type={IconType.Cross} />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}

                <tr>
                  <td className={`py-[20px] `}>&nbsp;</td>
                  {plan?.featureSections?.map((blank, i) => (
                    <td className={`${columnWithTags.includes(i) && 'activeColumn'}`} key={`black${i}`}>
                      &nbsp;
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <MobileTable plan={plan} />
        {bottomText?.heading && (
          <div className="mt-[53px] md:mt-[88px] text-center flex flex-col justify-center">
            <h3 className="text-h3">{bottomText?.heading}</h3>

            {bottomText?.link && (
              <div className="mt-[31px] flex justify-center">
                <Button link={bottomText?.link} type={ButtonType.Button} color={ColorType.Blue} />
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  );
}

export const MobileTable = ({ plan }) => {
  const [activeTab, setActiveTab] = useState(0);

  const iconVariants = {
    hidden: {
      scale: 0,
    },
    show: {
      scale: 1,
    },
    exit: {
      scale: 0,
    },
    transition: {
      duration: 0.2,
    },
  };

  return (
    <motion.div layout className="block md:hidden mobile-plan-table">
      <div className="relative flex justify-end">
        <div className={`w-[45%] py-[9px] ${plan?.plans[activeTab]?.tag ? 'bg-aqua ' : 'bg-transparent'} rounded-t-sm`}>
          <div
            className={` ${
              plan?.plans[activeTab]?.tag ? 'opacity-100' : 'opacity-0'
            }  text-[10px] text-center  uppercase font-semibold tracking-[-0.005em] transition-opacity duration-200 ease-out  `}
          >
            {plan?.plans[activeTab]?.tag || 'Most Popular'}
          </div>
        </div>
      </div>
      <div className="relative flex items-center justify-between">
        <div className="">
          <h4>Compare Plans</h4>
        </div>

        <Swiper
          onActiveIndexChange={(i) => setActiveTab(i.activeIndex)}
          className={`relative z-40 flex flex-col w-[45%] rounded-b-sm !ml-auto !mr-0  transition-colors duration-200 ease-in-out ${
            plan?.plans[activeTab]?.tag ? 'bg-white' : 'bg-transparent'
          }`}
          slidesPerView={1}
          grabCursor={true}
          navigation={true}
        >
          {plan?.plans?.map((planHead, i) => (
            <SwiperSlide key={`planSlider${i}`} style={{ height: 'auto' }}>
              <div
                key={`mobPlan${i}`}
                className={`relative  text-center  

                `}
                onClick={() => setActiveTab(i)}
              >
                <h5 className="text-[20px] font-bold leading-[2.50] tracking-[-0.005em]">{planHead?.name}</h5>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={`flex items-center  justify-between pt-[10px] `}>
        <div className="">
          <h5 className=" text-h6">Price</h5>
        </div>
        <div className={`w-[45%] text-center  transition-colors duration-200 ease-in-out  `}>
          <div className=" text-[18px] leading-[0.85] opacity-60  ">{plan?.plans[activeTab]?.price}</div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="">
          <h5 className="text-h6">Contract</h5>
        </div>
        <div className={`w-[45%] text-center transition-colors duration-200 ease-in-out py-[10px] `}>
          <div className=" text-[18px] leading-[0.85] opacity-60  ">{plan?.plans[activeTab]?.priceSuffix}</div>
        </div>
      </div>
      <div className="mt-[30px]">
        {plan?.featureSections?.map((featSection, i) => (
          <Fragment key={`MobileFeatSection${i}`}>
            <div className={`text-[16px] uppercase leading-[3.12] opacity-[0.39] ${i !== 0 && 'mt-[39px]'} `}>
              {featSection?.sectionName}
            </div>

            {featSection?.attributes?.map((attr, i) => (
              <Fragment key={`MobAttr${i}`}>
                <div className="flex items-center justify-between mt-[12px]">
                  <div className="leading-[3.57] text-[14px] ">{attr?.attributeName}</div>
                  <AnimatePresence>
                    {attr?.values[activeTab]?.boolean === true ? (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        transition={{ duration: 0.2 }}
                        className="flex justify-center w-[45%] text-center"
                      >
                        <Icon classes=" justify-center w-[18px] h-[18px]" type={IconType.Tick} />
                      </motion.div>
                    ) : (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        transition={{ duration: 0.2 }}
                        className="flex justify-center  w-[45%] text-center"
                      >
                        <Icon classes=" justify-center  w-[18px] h-[18px]" type={IconType.Cross} />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div className="w-full h-[1px] bg-black bg-opacity-[0.08] mt-[12px]"></div>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </motion.div>
  );
};
