import { useField } from 'formik';
import React from 'react';

export default function InputField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const labelClassName = props.labelClassName;

  return (
    <>
      <label className={`flex flex-col text-black items-start flex-auto ${props.className || ''}`}>
        <div className={`${labelClassName} font-medium text-[16px] leading-[1.65] mb-[7px]`}>{label}</div>
        <input
          className={`border focus:outline-none rounded  border-black  border-opacity-[0.16] text-black bg-transparent w-full px-4 py-[10px]  ${
            isInvalid && 'border border-red-500'
          }`}
          {...field}
          {...other}
          placeholder={props.placeholder}
        />

        {isInvalid && <div className="text-[red] text-[13px]">Required</div>}
      </label>
    </>
  );
}
