import React, { useEffect, useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import Image from '../elements/Image';

const VideoModal = ({
  previewImage,
  videoEmbed,
  className = '',
  imgClass = '',
  imageClass = '',
  iconClass = '',
  playBtnClass = 'bg-red text-white',
  caption,

  channel = 'youtube',
}) => {
  const [isOpen, setOpen] = useState(false);
  const [videoID, setVideoID] = useState(false);

  useEffect(() => {
    if (!videoEmbed) return;
    let videoSplit;
    if (videoEmbed.includes('v=')) {
      videoSplit = videoEmbed.split('v=');
    } else if (videoEmbed.includes('/embed/')) {
      videoSplit = videoEmbed.split('/embed/');
    }

    let videoID;
    if (videoSplit && videoSplit?.length >= 2) {
      videoID = videoSplit[1];
      const ampersandPosition = videoID.indexOf('&');
      if (ampersandPosition !== -1) {
        videoID = videoID.substring(0, ampersandPosition);
      }
    }

    setVideoID(videoID);
  }, [videoEmbed]);

  return (
    <>
      <div className={`${className} overflow-hidden`}>
        <div className={`relative  aspect-h-3 md:aspect-h-2 ${imgClass}`}>
          <Image image={previewImage} objectFit={'cover'} className={imageClass} />
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer group"
            onClick={() => setOpen(true)}
          >
            <div className="relative w-24 h-24 md:w-40 md:h-40">
              <div
                className={`w-full h-full transition-transform duration-300 ease-in-out transform rounded-full  group-hover:scale-110 ${playBtnClass} `}
              ></div>
              <div className="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <div className="w-[45px] md:w-[75px]">
                  <svg width="74" height="80" viewBox="0 0 74 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.44508 1.29354L4.44508 1.29355L4.44931 1.29587L70.9967 37.897C71.3616 38.0979 71.6659 38.393 71.8779 38.7517C72.0898 39.1103 72.2016 39.5192 72.2016 39.9358C72.2016 40.3523 72.0898 40.7612 71.8779 41.1199C71.6659 41.4785 71.3616 41.7736 70.9967 41.9746L4.44931 78.5756L4.44892 78.5759C4.10528 78.7651 3.71939 78.8643 3.32711 78.8644H3.32471C2.91156 78.8656 2.50596 78.7538 2.15173 78.5412L2.14511 78.5373C1.79682 78.3319 1.50805 78.0392 1.30726 77.6883C1.10658 77.3375 1.00069 76.9405 1.00004 76.5363V3.33445H1.00005L1.00002 3.32984C0.99817 2.92773 1.10053 2.53199 1.29713 2.18121C1.49334 1.83114 1.77672 1.53775 2.11976 1.32954C2.46979 1.12037 2.86865 1.00682 3.2764 1.0003C3.68495 0.993761 4.08802 1.0949 4.44508 1.29354Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        {caption && <div className="mt-3 opacity-40">{caption}</div>}
      </div>

      <ModalVideo channel={channel} autoplay={1} isOpen={isOpen} videoId={videoID} onClose={() => setOpen(false)} />
    </>
  );
};

export default VideoModal;
