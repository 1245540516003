import { Link } from 'gatsby';
import React, { useState } from 'react';
import { WpPage_Flexiblecontent_FlexibleContent_Apps } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import { Icon, IconType } from '../elements/Icon';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import { useLocation, useWindowSize } from 'react-use';

import Image from '../elements/Image';
import { backGroundSwitcher } from '../utils/backgroundSwitcher';

export default function TwoColumns({
  subheading,
  heading,
  section,
  appPages,
}: WpPage_Flexiblecontent_FlexibleContent_Apps) {
  const url = useLocation();

  const str = url?.pathname?.replace(/\//g, '');

  return (
    <Section {...section}>
      <div className={`container`}>
        <div className="space-y-5 text-center">
          {subheading && <h6 className="text-h6">{subheading}</h6>}
          <div className="max-w-[660px] mx-auto">{heading && <h2 className="text-h2">{heading}</h2>}</div>
        </div>

        <div className={`mt-[30px] md:mt-[90px] flex ml-[-26.5px] flex-wrap space-y-[22px] md:space-y-0 page-${str} `}>
          <div className="w-full md:w-1/2 pl-[26.5px] flex flex-col gap-y-[22px]">
            <AppCard
              {...appPages[0]?.typePage}
              title={appPages[0]?.title}
              uri={appPages[0]?.uri}
              slug={appPages[0]?.slug}
              isFirst
            />
            <AppCard
              {...appPages[2]?.typePage}
              title={appPages[2]?.title}
              uri={appPages[2]?.uri}
              slug={appPages[2]?.slug}
            />
          </div>
          <div className="w-full md:w-1/2 pl-[26.5px] flex flex-col gap-y-[22px] md:gap-y-[29px]">
            <AppCard
              {...appPages[1]?.typePage}
              title={appPages[1]?.title}
              uri={appPages[1]?.uri}
              slug={appPages[1]?.slug}
            />
            <AppCard
              {...appPages[3]?.typePage}
              title={appPages[3]?.title}
              uri={appPages[3]?.uri}
              slug={appPages[3]?.slug}
              isLast
            />
          </div>
        </div>
      </div>
    </Section>
  );
}

export const AppCard = ({
  summaryImage,
  secondarySummaryImage,
  backgroundColour,
  summaryDescription,
  title,
  imgClass,
  uri,
  slug,
  isLast,
  isFirst,
}) => {
  const [onHover, setOnHover] = useState(false);
  const { width } = useWindowSize();

  const gradientColour = backGroundSwitcher(backgroundColour);

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        whileHover="hover"
        initial="rest"
        className={`h-full relative   flex flex-col flex-auto rounded-[10px] group   `}
      >
        <div
          className={`absolute inset-0 rounded-[10px] ${gradientColour} opacity-0 group-hover:opacity-100 transition-opacity duration-200 `}
        ></div>
        <Link
          to={uri}
          className={`  rounded-[10px] h-full flex-auto flex flex-col  noisy relative app-${slug} relative `}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        >
          <div className="flex flex-col justify-between flex-auto h-full overflow-hidden group">
            <m.div
              style={{ willChange: 'transform' }}
              variants={{ hover: { scale: 1.05, y: [-3, 3] }, rest: { scale: 1, y: [0, 0] } }}
              transition={{
                duration: 0.6,
                ease: 'easeInOut',
                y: { duration: 2, delay: 0.6, repeat: Infinity, repeatType: 'reverse' },
              }}
              className={`${title !== 'Automatic Order Tags' && ''} pt-[11px] image-container  `}
            >
              <Image
                image={isLast || isFirst ? secondarySummaryImage || summaryImage : summaryImage}
                className="pointer-events-none gatsby-image"
              />
            </m.div>
            <div className="px-6 md:px-[41px] md:pb-[47px] content">
              <div className="text-h4">{title}</div>
              <div
                className="md:mt-[9px] md:max-w-[383px]"
                dangerouslySetInnerHTML={{
                  __html: summaryDescription,
                }}
              />
            </div>
          </div>
          <m.div
            style={{ willChange: 'transform' }}
            initial={{ scale: width > 768 ? 0 : 1 }}
            animate={{ scale: onHover ? 1 : width > 768 ? 0 : 1 }}
            transition={{ duration: 0.2, ease: [0.72, 0.36, 0.75, 1.06] }}
            className="md:absolute bottom-[47px] right-[50px] px-6 md:px-0 pb-[28px] md:pb-0 mt-[18px] md:mt-0"
          >
            <m.div>
              <Icon type={IconType.CircleBgArrowLarge} classes="w-[41px] h-[47px] md:w-[57px] md:h-[57px]" />
            </m.div>
          </m.div>
        </Link>
      </m.div>
    </LazyMotion>
  );
};
