import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { ReactSVG } from 'react-svg';

// @todo setup these props properly

interface ImageProps {
  image?: any;
  objectFit?: any;
  className?: any;
  loading?: any;
  style?: any;
}

function Image({ image, objectFit, className, loading, style, ...other }: ImageProps) {
  return (
    <>
      {image?.localFile?.childImageSharp ? (
        <GatsbyImage
          image={getImage(image?.localFile)}
          alt={image?.altText}
          imgStyle={{ objectFit: objectFit || 'contain' }}
          draggable="false"
          loading={loading}
          className={`${className}`}
          {...other}
        />
      ) : (
        <img
          {...other}
          src={image?.localFile?.publicURL}
          alt={image?.altText}
          draggable="false"
          className={`w-full h-full ${className}`}
        />
        // <ReactSVG
        //   src={image?.localFile?.publicURL}
        //   beforeInjection={(svg) => {
        //     svg.setAttribute('height', '100%');
        //     svg.setAttribute('width', '100%');
        //     svg.setAttribute('preserveAspectRatio', 'xMinYMid meet');
        //     // svg.setAttribute('style', style);
        //   }}
        //   className={`${className}`}
        // />
      )}
    </>
  );
}

export default Image;
