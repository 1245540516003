import React from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { Icon, IconType } from '../elements/Icon';
import { Link } from 'gatsby';
import { WpPage_Flexiblecontent_FlexibleContent_Pricing } from 'src/_generated/types';
import { MastheadPricingLink } from './Masthead';
import slugify from 'slugify';

import { AnimatePresence, domAnimation, LazyMotion, m, motion } from 'framer-motion';
import { ModalContext } from '../global/Layout';

export default function Pricing({
  section,
  subheading,
  heading,
  content,
  layout,
  pricingplan,
  link,
  hidePlans,
  anchorLinks,
  brands,
  importerBlocks,
  background,
}: WpPage_Flexiblecontent_FlexibleContent_Pricing) {
  return (
    <Section {...section}>
      {layout === 'individual' ? (
        <IndividualLayout heading={heading} content={content} pricingplan={pricingplan} background={background} />
      ) : layout === 'importer' ? (
        <ImporterLayout
          subheading={subheading}
          heading={heading}
          content={content}
          link={link}
          importerBlocks={importerBlocks}
          background={background}
        />
      ) : (
        <MainLayout
          subheading={subheading}
          heading={heading}
          content={content}
          pricingplan={pricingplan}
          hidePlans={hidePlans}
          anchorLinks={anchorLinks}
          brands={brands}
          link={link}
        />
      )}
    </Section>
  );
}

export const MainLayout = ({ subheading, heading, content, hidePlans, link, pricingplan, anchorLinks, brands }) => {
  return (
    <div className="p-2 mx-auto md:px-5 md:py-4">
      <div className="w-full h-full text-white rounded-lg bg-dark-purple">
        <div className="container pb-[68px] md:pb-[130px]">
          <div className=" relative flex justify-center items-center pt-32 md:pt-[10rem]">
            <div className="text-center">
              <h6 className="text-h6">{subheading}</h6>
              <h1 className="text-h1 mt-[15px]">{heading}</h1>
              <div
                className="p-large max-w-[644px] mx-auto mt-[21px] opacity-60 "
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className="mt-[39px] flex justify-center">
                <Button color={ColorType.White} icon={IconType.ArrowRight} link={link} type={ButtonType.Button} />
              </div>
            </div>
          </div>
          {!hidePlans && (
            <>
              <div className="mt-[79px] md:mt-[117px] max-w-[1112px] mx-auto flex flex-wrap lg:flex-nowrap items-start justify-center lg:space-x-[22px] gap-y-[38px]">
                {pricingplan?.plan?.length &&
                  pricingplan?.plan[0]?.pricingTable?.plans?.map((plan, i) => (
                    <div key={`plan${i}`} className={` w-full ${plan?.tag && 'lg:mt-[-41px] '} `}>
                      <PricingBlock
                        {...plan}
                        i={i}
                        className={`${plan?.tag ? 'lg:max-w-[360px]' : 'lg:max-w-[330px] '} ${
                          i === 0 && ' lg:ml-auto'
                        } ${i === pricingplan?.plan[0]?.pricingTable?.plans?.length - 1 && ' lg:mr-auto'} `}
                        features={pricingplan?.plan[0]?.pricingTable?.featureSections}
                      />
                    </div>
                  ))}
              </div>

              <div className="mt-[35px] flex flex-col md:flex-row space-y-[20px] md:space-y-0 justify-center items-center  md:space-x-[30px] ">
                {anchorLinks?.map((link, i) => (
                  <AnchorLink key={`anchor${i}`} link={link.link} />
                ))}
              </div>
            </>
          )}

          <Brands {...brands} />
        </div>
      </div>
    </div>
  );
};

export const AnchorLink = ({ link }) => {
  return (
    <motion.a
      href={link?.url}
      className="flex items-center space-x-[7px]"
      initial="anchorRest"
      whileHover="anchorHover"
      animate="anchorRest"
    >
      <span>{link?.title}</span>
      <div className="mt-[1px]">
        <motion.svg
          variants={{
            anchorRest: { scale: 1, transition: { duration: 0.4 } },
            anchorHover: {
              scale: 1.3,
              transition: { duration: 0.4 },
            },
          }}
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.circle
            variants={{
              anchorRest: { pathLength: 1, transition: { duration: 0.4 } },
              anchorHover: {
                pathLength: 0,
                transition: { duration: 0.4 },
              },
            }}
            cx="11.5"
            cy="11.8481"
            r="11"
            transform="rotate(90 11.5 11.8481)"
            stroke="white"
            strokeOpacity="0.29"
          />
          <path d="M11.9258 7.16309L11.9258 16.5335" stroke="white" strokeWidth="1.06481" />
          <path d="M8.51758 13.126L11.925 16.5334L15.3324 13.126" stroke="white" strokeWidth="1.06481" />
        </motion.svg>
      </div>
    </motion.a>
  );
};

export const ImporterLayout = ({ subheading, heading, content, link, importerBlocks, background }) => {
  const { bottomLeftBlock, bottomRightBlock, leftBlock, middleBlock, rightMiddleBlock, topRightBlock } = importerBlocks;
  return (
    <div className={`p-2 md:px-5 md:py-4 `}>
      <div className="rounded-md purple-gradient">
        <div className="relative flex flex-wrap items-end noisy ">
          <div className="container pb-[70px] md:pb-[156px]">
            <div className=" relative flex justify-center items-center pt-32 md:pt-[10rem]">
              <div className="text-center">
                <h6 className="text-h6">{subheading}</h6>
                <h1 className="text-h1 mt-[15px]  max-w-[572px] mx-auto">{heading}</h1>
                <div
                  className="p-large max-w-[685px] mx-auto mt-[21px]"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                {link && (
                  <div className="mt-[39px]">
                    <Button color={ColorType.White} icon={IconType.ArrowRight} link={link} type={ButtonType.Button} />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-[30px] md:mt-[80px] flex  w-full md:ml-[-46px] flex-wrap ">
              <div className="w-full lg:w-2/3 md:pl-[46px] flex flex-col gap-y-[24px] md:gap-y-[46px]">
                <div className="flex w-full md:-ml-[46px] flex-wrap justify-center">
                  <div className="md:pl-[46px] w-full md:w-1/2">
                    <ImporterPriceBlock {...leftBlock} />
                  </div>
                  <div className="md:pl-[46px] w-full md:w-1/2 mt-[24px] md:mt-0">
                    <ImporterPriceBlock {...middleBlock} />
                  </div>
                </div>
                <div className="flex flex-auto md:pl-[46px] ">
                  <LinkBlock {...bottomLeftBlock} />
                </div>
              </div>
              <div className="w-full justify-between lg:w-1/3 md:pl-[46px] gap-y-[24px] md:gap-y-[44px] flex flex-col mt-[24px] md:mt-[46px] lg:mt-0">
                <SimpleImporterBlock {...topRightBlock} />
                <SimpleImporterBlock {...rightMiddleBlock} />
                <SimpleImporterBlock {...bottomRightBlock} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SimpleImporterBlock = ({ content, featureprice, heading, layout }) => {
  return (
    <div className="md:pl-[46px] lg:pl-0">
      <div className="bg-white rounded-[10px] text-center px-6 py-[32px]">
        <FeaturePriceContent heading={heading} featureprice={featureprice} content={content} layout={layout} />
      </div>
    </div>
  );
};

export const LinkBlock = ({ heading, link }) => {
  return (
    <ModalContext.Consumer>
      {(setShowModal) => {
        return (
          <div className="bg-white rounded-[10px] pt-[35px] py-[32px]  px-[42px]">
            <h5 className="text-[18px] font-extrabold leading-[1.5] tracking-[-0.005em] font-heading text-center md:text-left  ">
              {heading}
            </h5>
            <div className="mt-[13px] flex justify-center md:justify-start">
              <div onClick={() => setShowModal(true)}>
                <MastheadPricingLink link={link} />
              </div>
            </div>
          </div>
        );
      }}
    </ModalContext.Consumer>
  );
};

export const IndividualLayout = ({ heading, content, pricingplan }) => {
  return (
    <div>
      <div className="container">
        <div className="flex justify-center w-full">
          <div className="w-full text-center">
            <h2 className="text-h2">{heading}</h2>
            <div
              className="mt-[9px] max-w-[644px] p-large opacity-60 mx-auto"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="mt-[21px] flex justify-center">
              <SalesLink />
            </div>
            <div className="mt-[48px] md:mt-[90px] space-y-[36px]">
              {pricingplan?.plan?.map((plan, i) => (
                <IndividualBlock key={`block${i}`} plan={plan} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PricingBlock = ({ name, price, priceSuffix, tag, features, i, className }) => {
  return (
    <div className={`rounded-[10px] w-full pricing-block ${className}  `}>
      {tag && (
        <div className="bg-gradient-to-r from-blue to-pink rounded-t-[10px]">
          <div className="rounded-t-[10px] noisy flex justify-center    text-[13px] uppercase py-[10px] font-semibold">
            {tag}
          </div>
        </div>
      )}
      <div className={`text-center bg-white ${tag ? '' : 'rounded-t-[10px] '} text-dark-purple `}>
        <h2 className={` ${i === 1 ? 'text-[27px]' : 'text-[25px]'} font-extrabold  tracking-[-0.005em] pt-[44px] `}>
          {name}
        </h2>
        <div className={`${i === 1 ? 'text-[15px]' : 'text-[14px]'}  opacity-60 leading-[2.29]`}>
          Full Ablestar App Suite
        </div>
        <div
          className={`mt-[36px] ${
            i === 1 ? 'text-[55px]' : 'text-[50px]'
          }  text-[#264BB3] leading-[0.64] tracking-[-0.005em] `}
        >
          {price}
        </div>
        <div className={`${i === 1 ? 'text-[17.45px]' : 'text-[16px]'} opacity-[0.4] leading-[2] mt-[10px]`}>
          {priceSuffix}
        </div>
        <div className="flex justify-center">
          <SalesLink i={i} iconClass="mt-[1px]" />
        </div>
      </div>

      <div className="text-center bg-light-grey rounded-b-[10px] text-black pt-[36px] pb-[44px]">
        <div className="space-y-[14px]">
          {features[0]?.attributes?.map((attr, index) => (
            <AnimatePresence>
              <BlockFeature key={`blockFeat${index}`} {...attr} i={i} index={index} />
            </AnimatePresence>
          ))}
        </div>
        <div className={`flex justify-center mt-[41px] ${i === 1 ? 'text-[17.45px]' : 'text-[16px]'} `}>
          <Button
            type={ButtonType.Button}
            color={ColorType.Blue}
            link={{ title: 'Add to my store', url: '#add' }}
            icon={IconType.ArrowRight}
          />
        </div>
      </div>
    </div>
  );
};

export const BlockFeature = ({ i, values, attributeName, index, className = 'justify-center' }) => {
  return (
    <>
      {values[i]?.boolean === null && values[i]?.type === 'text' ? null : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          key={`feat${index}`}
          transition={{ duration: 0.3, delay: index / 12 }}
          className={`flex items-center ${className}  space-x-[8px] `}
        >
          {values[i]?.boolean === true ? (
            <Icon classes="w-[21px] h-[21px]" type={IconType.Tick} />
          ) : (
            <Icon classes="w-[21px] h-[21px]" type={IconType.Cross} />
          )}

          <div className={`${i === 1 ? 'text-[15px]' : 'text-[14px]'}  leading-[1.39] tracking-[-0.005em] `}>
            {attributeName}
          </div>
        </motion.div>
      )}
    </>
  );
};

export const Brands = ({ heading, content, logos }) => (
  <div className="mt-[68px] md:mt-[93px] flex justify-center flex-col items-center">
    <div className="text-center">
      <h2 className="text-h2">{heading}</h2>
      <div
        className="mt-[31px] p-large opacity-60 mx-auto max-w-[792px]"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
    <div className="mt-[45px] flex flex-wrap ml-[-30px] lg:ml-[-76px] items-center justify-center gap-y-[20px]">
      {logos?.map((logo, i) => (
        <div key={`logo${i}`} className="pl-[30px] lg:pl-[76px]">
          <Image image={logo} className="max-w-[76px]" />
        </div>
      ))}
    </div>
  </div>
);

export const SalesLink = ({
  i,
  title = 'Talk to Sales',
  className = 'items-center justify-center mt-[27px]',
  iconClass = 'mt-[3px] ',
  url = '/contact',
}) => {
  return (
    <motion.div className="max-w-max" initial="rest" whileHover="hover" animate="rest">
      <Link
        to={url}
        className={`${i && i === 1 ? 'text-[17.45px]' : 'text-[16px]'}   flex ${className} space-x-[3px] pb-[30px]`}
      >
        <div>{title}</div>
        <div className={`${iconClass}`}>
          <motion.svg
            variants={{
              rest: {
                scale: 1,
                x: 0,

                transition: { duration: 0.4 },
              },
              hover: {
                scale: 1.3,
                x: 10,
                transition: { duration: 0.4 },
              },
            }}
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.circle
              variants={{
                rest: {
                  cx: 11.5,
                  cy: 11.5,
                  r: 11.5,
                  transition: { duration: 0.3 },
                },
                hover: {
                  cx: 11.5,
                  cy: 11.5,
                  r: 0,
                  transition: { duration: 0.3 },
                },
              }}
              cx="11.5"
              cy="11.5"
              r="11.5"
              fill="black"
            />
            <motion.path
              variants={{
                rest: { scale: 1, stroke: 'white', transition: { duration: 0.4 } },
                hover: { scale: 1.1, stroke: 'black', transition: { delay: 0.2, duration: 0.1 } },
              }}
              d="M6.81494 11.0742L16.1853 11.0742"
              stroke="currentColour"
              strokeWidth="1.06481"
            />
            <motion.path
              variants={{
                rest: { scale: 1, stroke: 'white' },
                hover: {
                  scale: 1.1,
                  stroke: 'black',

                  transition: { delay: 0.2, duration: 0.1 },
                },
              }}
              d="M12.7778 14.4814L16.1852 11.074L12.7778 7.66663"
              stroke="currentColour"
              strokeWidth="1.06481"
            />
          </motion.svg>
        </div>
      </Link>
    </motion.div>
  );
};
export const IndividualBlock = ({ plan, background }) => {
  let gradientColour = '';

  switch (plan?.pricingTable?.background?.backgroundColour) {
    case 'purple':
      gradientColour = 'purple-gradient';
      break;
    case 'green':
      gradientColour = 'green-gradient';
      break;
    case 'blue':
      gradientColour = 'blue-gradient';
      break;
    case 'purple-blue':
      gradientColour = 'blue-purple-gradient';
      break;
    case 'bright-blue':
      gradientColour = 'bright-blue-gradient';
      break;
    case 'yellow':
      gradientColour = 'yellow-gradient';
      break;
  }

  return (
    <motion.div
      style={{ scrollMarginTop: '150px' }}
      id={slugify(plan?.title, { lower: true })}
      className={`  transition-colors duration-200 rounded-[10px] pricing-individual-block cursor-pointer group relative `}
    >
      <div
        className={`absolute  inset-0 rounded-[10px] ${gradientColour} opacity-0 group-hover:opacity-100 transition-opacity duration-200 `}
      ></div>
      <a
        target="_blank"
        href={plan?.pricingTable?.link?.url}
        className="md:flex py-[39px] px-6 md:pl-[39px] md:pr-[30px] justify-between rounded-[10px] noisy relative"
      >
        <div className="w-full md:w-[45%]">
          {plan?.pricingTable?.icon && (
            <div className="w-[68px] h-[68px] bg-white rounded-[4px]">
              <Image image={plan?.pricingTable?.icon} className="w-full h-full max-w-[40px] mx-auto" />
            </div>
          )}
          <div className="mt-[20px] md:mt-[44px] text-left">
            <h3 className="text-h3">{plan?.title}</h3>
            <div className="md:mt-[18px]">
              <SalesLink
                className="justify-start mt-[5px]"
                title={plan?.pricingTable?.link?.title}
                url={plan?.pricingTable?.link?.url}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[4px] gap-y-[25px] gap-x-[25px] flex flex-col lg:flex-row px-[30px] py-[33px] xl:gap-x-0 xl:space-x-[92px]  flex-auto justify-center w-full md:w-[55%] ">
          {plan?.pricingTable?.plans?.slice(0, 2)?.map((planGroup, i) => (
            <div key={`planGroup${i}`} className="flex flex-col flex-auto">
              <div className="flex justify-between font-semibold">
                <div className="uppercase ">{planGroup?.name}</div>
                <div>{planGroup?.price}</div>
              </div>
              <div className="mt-[10px] w-full h-[1px] bg-dark-purple bg-opacity-20 mb-[20px]"></div>
              <div className="text-left space-y-[10px]">
                {plan?.pricingTable?.featureSections &&
                  plan?.pricingTable?.featureSections[0]?.attributes?.map((attr, index) => (
                    <BlockFeature key={`indvFeat${index}`} {...attr} i={i} className="justify-start" />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </a>
    </motion.div>
  );
};

export const ImporterPriceBlock = ({ heading, content, price, items, link, layout }) => {
  return (
    <div className="rounded-[10px] text-center ">
      <div className="bg-white py-[30px] rounded-t-[10px] px-6">
        <FeaturePriceContent layout={layout} heading={heading} content={content} price={price} />
      </div>
      <div className="bg-light-grey rounded-b-[10px] pt-[30px] pb-[42px] px-6">
        <div className="space-y-[4px]">
          {items?.map((item, i) => (
            <div key={`importBlockitem${i}`}>{item?.item}</div>
          ))}
        </div>
        <div className="mt-[24px] flex justify-center">
          <Button link={link} type={ButtonType.Button} color={ColorType.Black} icon={IconType.ArrowRight} />
        </div>
      </div>
    </div>
  );
};

export const FeaturePriceContent = ({ heading, content, price, layout, featureprice }) => {
  return (
    <div className={`flex flex-col`}>
      <h4 className="text-h4">{heading}</h4>
      <div className={`opacity-[0.4] ${layout !== 'price' && 'order-1 mt-[20px]'}`}>{content}</div>
      <div className={`text-dark-blue text-[50px] leading-[0.64] mt-[20px] ${layout === 'price' && 'pb-[8px]'} `}>
        {price || featureprice}
      </div>
    </div>
  );
};
