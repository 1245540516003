import React, { Fragment, useEffect, useState } from 'react';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';

import { useWindowSize } from 'react-use';

import { Link } from 'gatsby';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '../elements/buttons/Button';
import { Icon, IconType } from '../elements/Icon';
import useGlobal from '~/hooks/useGlobal';
import { NavToggle } from '../elements/animations/NavToggle';
import SmartLink from './SmartLink';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ data, global, blogHeader }) {
  const [headerBg, setHeaderBg] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeHover, setActiveHover] = useState(null);

  const header = data.optionsHeader;

  const scrollEvent = (e) => {
    if (window.scrollY > 100) {
      setHeaderBg(true);
    } else {
      setHeaderBg(false);
    }
  };

  useEffect(() => {
    if (window.scrollY > 100) {
      setHeaderBg(true);
    } else {
      setHeaderBg(false);
    }

    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  const { width } = useWindowSize();

  const openHandler = (i) => {
    setOpen(true);
    setActiveMenu(i);
    setActiveHover(null);
  };

  const reset = () => {
    setOpen(false);
    setActiveMenu(null);
  };

  return (
    <>
      <motion.header
        role="banner"
        initial={{ y: '0rem' }}
        animate={{ y: width > 768 && headerBg ? '-1rem' : '0rem' }}
        transition={{ duration: 0.4 }}
        className={`fixed top-0 left-0 transition-colors mx-auto ease-in-out duration-300  right-0 z-30 ${
          blogHeader && !headerBg && 'text-white'
        } ${openMobile && 'bg-white'} ${
          headerBg ? 'bg-white shadow-md' : !headerBg && !openMobile && 'bg-transparent'
        }  `}
      >
        <div className={`header-container py-1 mx-auto transition-transform duration-100`}>
          <motion.div
            initial={{ paddingBottom: width > 1023 ? '2rem' : '1rem' }}
            animate={{ paddingBottom: headerBg && width > 1023 ? '0.5rem' : width > 1023 ? '2rem' : '1rem' }}
            transition={{ duration: 0.4 }}
            className={`  flex  items-center justify-between pt-[0.7rem] pb-4 md:pb-4 md:pt-7 lg:justify-start xl:space-x-10`}
          >
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/" className="md:mt-[-7px]">
                <span className="sr-only">Ablestar</span>
                <Image
                  image={
                    headerBg || openMobile
                      ? header.header.logoAlt
                      : blogHeader && !headerBg
                      ? header.header.logoLight
                      : header.header.logo
                  }
                  className="max-w-[109px] md:max-w-[119px]"
                />
              </Link>
            </div>
            <div className="-my-2 mr-[-0.8rem] lg:hidden">
              <button
                onClick={() => setOpenMobile(!openMobile)}
                className="inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none "
              >
                <span className="sr-only">Open menu</span>
                <div className=" w-[23px] h-[23px] mr-[10px]">
                  <NavToggle headerBg={headerBg} dark={blogHeader} isOpen={openMobile} />
                </div>
              </button>
            </div>
            <nav className="hidden space-x-5 xl:space-x-10 lg:flex">
              {header.header.menu.map((item, i) => (
                <Fragment key={`menu${i}`}>
                  {item.type === 'submenu' ? (
                    <div className="relative">
                      <>
                        <motion.div
                          whileHover="hover"
                          onHoverStart={() => {
                            openHandler(i);
                          }}
                          onHoverEnd={() => reset()}
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group  inline-flex items-center text-base cursor-pointer font-medium space-x-1.5  hover:text-gray-900 focus:outline-none '
                          )}
                        >
                          <span className={`${headerBg ? 'group-hover:text-blue' : 'hover:opacity-50'}`}>
                            {item.link.title}
                          </span>
                          <motion.div variants={{ hover: { y: 3 } }} transition={{ duration: 0.2 }}>
                            <Icon
                              type={IconType.ArrowDown}
                              classes={`${classNames(
                                open && activeMenu === i ? 'text-gray-600 transform rotate-180' : ' text-gray-400'
                              )} ${
                                headerBg ? 'group-hover:text-blue' : 'group-hover:opacity-50'
                              }  transition-transform duration-150 ease-in-out  `}
                              aria-hidden="true"
                            />
                          </motion.div>
                        </motion.div>
                        <LayoutGroup>
                          <AnimatePresence>
                            {open && activeMenu === i && (
                              <motion.div
                                style={{ willChange: 'opacity' }}
                                onHoverStart={() => {
                                  openHandler(i);
                                }}
                                key={`nav${i}`}
                                onHoverEnd={() => reset()}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className={`absolute z-10 w-screen rounded-lg  ${
                                  item?.submenu?.length > 3
                                    ? 'max-w-[813px] bg-white lg:left-[120%]'
                                    : 'max-w-[175px] lg:left-[50%]'
                                }  px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0  lg:-translate-x-1/2`}
                              >
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                  <div
                                    className={`relative grid rounded-lg items-center ${
                                      item?.submenu?.length > 3
                                        ? 'grid-cols-2 grid-rows-3 px-5  sm:gap-[16px]'
                                        : 'sm:gap-[8px] px-[14px]'
                                    }    py-[22px] bg-white  `}
                                  >
                                    {item?.submenu.map((subitem, q) => (
                                      <motion.div
                                        key={`mItem${q}${subitem?.link?.title}`}
                                        onHoverStart={() => setActiveHover(q)}
                                        whileTap={{ scale: 0.99 }}
                                      >
                                        <Link
                                          onClick={() => reset()}
                                          to={subitem.link.url}
                                          className={`flex items-center relative  ${
                                            !subitem?.icon ? 'p-3 ' : 'py-[14px] px-[18px] '
                                          }  text-dark-purple rounded-lg `}
                                        >
                                          {/* <AnimatePresence> */}
                                          {activeHover === q && (
                                            <motion.div
                                              initial={{ opacity: 0 }}
                                              animate={{ opacity: 1 }}
                                              exit={{ opacity: 0 }}
                                              transition={{ duration: 0.3 }}
                                              layoutId={`menuBG`}
                                              className="absolute inset-0 rounded-lg bg-light-grey "
                                            ></motion.div>
                                          )}
                                          {/* </AnimatePresence> */}

                                          {subitem?.icon && (
                                            <Image
                                              image={subitem?.icon}
                                              className="w-full h-full max-h-[47px] max-w-[47px] relative z-[100]"
                                            />
                                          )}
                                          <div className={`relative ${subitem?.icon ? 'ml-4 ' : 'ml-[20%]'}  z-[100]`}>
                                            <p className="text-[15px] leading-[1.62] font-bold font-heading tracking-[-0.005em]  ">
                                              {subitem.link.title}
                                            </p>
                                            {subitem?.description && (
                                              <p className="text-[12.5px] text-gray-500">{subitem?.description}</p>
                                            )}
                                          </div>
                                        </Link>
                                      </motion.div>
                                    ))}
                                  </div>
                                </div>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </LayoutGroup>
                      </>
                    </div>
                  ) : (
                    <>
                      <motion.div
                        className=""
                        whileHover="hover"
                        whileTap={{ scale: 0.95 }}
                        onHoverStart={() => {
                          openHandler(i);
                        }}
                        onHoverEnd={() => reset()}
                      >
                        <SmartLink
                          url={item.link.url}
                          activeClassName="text-blue"
                          className={`transition-opacity duration-200 ease-in-out inline-flex items-center group space-x-1.5 ${
                            headerBg ? 'hover:text-blue' : 'hover:opacity-50'
                          } `}
                        >
                          <div>{item.link.title}</div>
                          {item.link.title === 'Help Center' && (
                            <motion.div variants={{ hover: { x: 2, y: -2 } }} transition={{ duration: 0.2 }}>
                              <Icon
                                classes={` ${
                                  headerBg ? 'group-hover:text-blue' : 'group-hover:opacity-50'
                                }  transition-transform duration-150 ease-in-out rotate-[208deg] `}
                                type={IconType.ArrowDown}
                              />
                            </motion.div>
                          )}
                        </SmartLink>
                      </motion.div>
                    </>
                  )}
                </Fragment>
              ))}
            </nav>
            <div className="items-center justify-end hidden lg:flex lg:flex-1 lg:w-0">
              <Button
                modal
                link={header.header.ctaLink}
                type={ButtonType.Button}
                color={headerBg ? ColorType.Blue : ColorType.Black}
                className="font-heading text-[14px] xl:text-[16px] leaidng-[1.62] font-medium tracking-[-0.005em] "
              />
            </div>
          </motion.div>
        </div>
      </motion.header>
      <AnimatePresence>{openMobile && <MobileMenu header={header} setOpenMobile={setOpenMobile} />}</AnimatePresence>
    </>
  );
}

export const MobileMenu = ({ header, setOpenMobile }) => {
  const { social } = useGlobal();
  return (
    <motion.div
      initial={{ y: -50, opacity: 0, height: '0%' }}
      animate={{ y: 0, opacity: 1, height: '100%' }}
      exit={{ opacity: 0 }}
      key={`mobileNav`}
      transition={{ duration: 0.1, height: { duration: 0.4, ease: [0.65, 0.05, 0.67, 0.87] } }}
      className="fixed top-[61px] z-[150] transition z-[100] lg:hidden left-0 right-0  bottom-0 pb-[38px] px-[24px] md:px-[32px] bg-white overflow-scroll"
    >
      {header.header.menu.map((item, i) => (
        <DropDown setOpenMobile={setOpenMobile} key={`mobileMenu${i}`} {...item} i={i} header={header} />
      ))}

      <div className="mt-[24px] justify-center">
        <Button fullWidth link={header.header.ctaLink} type={ButtonType.Button} color={ColorType.Black} />
      </div>
      <div className="mt-[37px] flex space-x-[28px] justify-center">
        {social?.map((soc, i) => (
          <a key={`social${i}`} href={soc?.link?.url} target="_blank">
            <Image image={soc?.icon} className="max-w-[25px]" />
          </a>
        ))}
      </div>
      <div className="mt-[32px] text-[14px] leading-[1.86]  ">
        <div className="flex justify-around text-black">
          <Link className="underline opacity-60" to="/terms">
            Terms & Conditions
          </Link>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 rounded-full bg-[#20FFA1]"></div>
            <span className="opacity-60">Service Status</span>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-[27px] opacity-60 ">© 2021. Ablestar. All Rights Reserved.</div>
        </div>
      </div>
    </motion.div>
  );
};

export const DropDown = ({ link, submenu, i, setOpenMobile, type }) => {
  const [openMenu, setOpenMenu] = useState(i === 0 ? true : false);

  return (
    <div className="w-full ">
      {i !== submenu?.length - 1 && i !== 0 && <div className="w-full h-[0.5px] bg-black bg-opacity-[0.2]"></div>}

      <div className="flex items-center " onClick={submenu?.length > 1 ? () => setOpenMenu(!openMenu) : undefined}>
        <h5 className="w-full text-h5 my-[14px] text-dark-purple ">
          <SmartLink url={link.url || '#'}>{link?.title}</SmartLink>
        </h5>
        {type === 'submenu' && submenu?.length > 1 && (
          <div
            className={`${openMenu ? ' rotate-[270deg]' : 'rotate-90'} transition-transform duration-150 ease-in-out `}
          >
            <Icon type={IconType.CircleBgArrow} />
          </div>
        )}
      </div>

      <AnimatePresence exitBeforeEnter>
        {openMenu && type === 'submenu' && (
          <div className=" space-y-[20px] mb-[34px] mt-[10px] text-dark-purple">
            {submenu?.map((childItem, i, arr) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.4,
                  delay: (i + 1) / 16,
                  ease: 'easeIn',
                }}
                key={`mobileChildItem${i}`}
              >
                <Link to={childItem?.link?.url} onClick={() => setOpenMobile(false)} className="flex items-center">
                  {childItem?.icon && <Image image={childItem?.icon} className="w-full max-w-[25px]" />}
                  <div className="ml-[14px]">{childItem?.link?.title}</div>
                </Link>
              </motion.div>
            ))}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
