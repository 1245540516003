import { graphql, useStaticQuery } from 'gatsby';

const useCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCategory {
        nodes {
          name
        }
      }
    }
  `);

  return data;
};

export default useCategories;
