export const backGroundSwitcher = (backgroundColour) => {
  let gradientColour = '';

  switch (backgroundColour) {
    case 'purple':
      gradientColour = 'purple-gradient';
      break;
    case 'green':
      gradientColour = 'green-gradient';
      break;
    case 'blue':
      gradientColour = 'blue-gradient';
      break;
    case 'purple-blue':
      gradientColour = 'blue-purple-gradient';
      break;
    case 'yellow':
      gradientColour = 'yellow-gradient';
      break;
    case 'bright-blue':
      gradientColour = 'bright-blue-gradient';
      break;
  }

  return gradientColour;
};
