import { Link } from 'gatsby';
import React from 'react';
import { Button, ButtonType } from '../elements/buttons/Button';
import { Icon, IconType } from '../elements/Icon';
import Image from '../elements/Image';

const InterstitialArticle = ({ article }) => {
  return (
    <Link to={article?.uri}>
      <div className="mb-[48px] md:mb-[73px] mx-auto bg-dark-blue text-white transition duration-200 rounded-lg group hover:opacity-90 ">
        <div className="flex flex-col-reverse md:flex-row px-[17px] pt-[26px] pb-[40px]  md:p-[50px] justify-between  items-center">
          <div className="mt-[20px] md:mt-0 pr-0 md:pr-6">
            <div className="  font-bold leading-[1.15] text-[16px] md:text-[35px] tracking-[-0.01em] max-w-[488px]  ">
              {article?.title}
            </div>
            <div className="mt-[23px] flex items-center ">
              <span className="mr-[5px]">Read Article</span>
              <Icon type={IconType.CircleBgArrow} classes={`invert group-hover:invert-0`} />
            </div>
          </div>
          <div className="relative flex-none   max-w-[329px] max-h-[214px] w-full h-full ">
            <Image
              image={article?.featuredImage?.node}
              objectFit="cover"
              className=" w-full h-full max-w-[329px] max-h-[214px]"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default InterstitialArticle;
