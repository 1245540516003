import { Form } from 'formik';
import React, { useEffect, useState } from 'react';
import useCategories from '~/hooks/useCategories';
import { Button, ButtonType, ColorType } from '../elements/buttons/Button';
import { IconType } from '../elements/Icon';
import PostPreview from './PostPreview';

const ResourceGrid = ({
  heading,
  bgColour = 'bg-white',
  allWpPost,
  button,
  viewAllLink,
  viewAllTitle,
  filter = false,
  postAmount = 6,
}) => {
  const { allWpCategory } = useCategories();
  const [filterQuery, setFilterQuery] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState(allWpPost?.nodes);

  const handleChange = (e) => {

    if (e !== null || e.target.value !== 'All') {
      setFilterQuery(e.target.value);
    } else setFilterQuery(null);
  };


  useEffect(() => {
    setFilteredPosts(
      filterQuery !== null
        ? allWpPost?.nodes?.filter((p) => p.categories.nodes?.some((cat) => cat.name === filterQuery))
        : allWpPost?.nodes
    );
  }, [filterQuery]);

  return (
    <section className={`py-[68px] md:py-[118px] ${bgColour}`}>
      <div className="container">
        <div className={`flex items-center ${filter ? 'justify-between' : 'justify-center'} `}>
          <h2 className=" text-h2">{heading}</h2>
          {filter && (
            <div className="flex cursor-pointer">
              <span>Filter:</span>{' '}
              <form>
                <select
                  onChange={handleChange}
                  className="bg-transparent cursor-pointer focus:outline-none"
                  name=""
                  id=""
                >
                  <option value={null}>All</option>
                  {allWpCategory?.nodes?.map((cat, i) => (
                    <option className="text-black" key={`cat${i}`} value={cat?.name}>
                      {cat?.name}
                    </option>
                  ))}
                </select>
              </form>
            </div>
          )}
        </div>

        <ResourceGridInner
          posts={filter && filterQuery !== null ? filteredPosts : allWpPost?.nodes || allWpPost}
          postAmount={postAmount}
        />

        {button && (
          <div className="mt-[48px] md:mt-[97px] flex justify-center">
            <Button
              color={ColorType.Blue}
              type={ButtonType.Button}
              icon={IconType.ArrowRight}
              link={{
                title: ` View all ${viewAllTitle}`,
                url: viewAllLink,
              }}
            />
          </div>
        )}

        {filter && filterQuery !== null && (
          <div className="mt-[48px] md:mt-[97px] flex justify-center">
            <Button
              color={ColorType.Blue}
              type={ButtonType.Button}
              icon={IconType.ArrowRight}
              link={{
                title: filterQuery !== null ? `View All ${filterQuery} ` : 'View all content',
                url:
                  filterQuery !== null
                    ? filteredPosts[0]?.categories?.nodes[0]?.uri
                    : viewAllLink
                    ? viewAllLink
                    : '/all-posts',
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ResourceGrid;

export const ResourceGridInner = ({ posts, postAmount }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <div className="mt-[48px] md:mt-[84px] flex flex-wrap ml-[-24px] gap-y-[49px]">
      {posts?.slice(0, postAmount)?.map((post, i) => (
        <div
          key={`post${i}`}
          onMouseEnter={() => setHoverIndex(i)}
          onMouseLeave={() => setHoverIndex(null)}
          className={`pl-[24px] w-full sm:w-1/2 lg:w-1/3 transition-opacity duration-200 ease-in-out ${
            hoverIndex !== null && i !== hoverIndex ? 'opacity-60' : 'opacity-100'
          }`}
        >
          <PostPreview {...post} />
        </div>
      ))}
    </div>
  );
};
