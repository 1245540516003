import React from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_TwoColumns } from 'src/_generated/types';
import { backGroundSwitcher } from '../utils/backgroundSwitcher';
import { domAnimation, LazyMotion, m, Variants } from 'framer-motion';

export default function TwoColumns({
  section,
  column,
  image,
  flipColumns,
  layout,
  colour,
  animateMedia,
}: WpPage_Flexiblecontent_FlexibleContent_TwoColumns) {
  const gradientColour = backGroundSwitcher(colour?.backgroundColour);

  return (
    <Section {...section}>
      <div className={`container  `}>
        <div className={` ${layout === 'colour-block' && gradientColour} rounded-[10px]`}>
          <div
            className={`grid overflow-hidden items-center space-y-12 lg:space-y-0 lg:grid-cols-2 text-center lg:text-left ${
              layout === 'help-center' &&
              'bg-dark-purple text-white rounded-[10px] px-6 pt-[70px] pb-[60px] md:py-[54px] md:pl-[117px] md:pr-[83px]'
            } ${layout === 'colour-block' && 'noisy pl-6 md:pl-[86px] py-[65px] rounded-[10px]  '}`}
          >
            <Content {...column} layout={layout} />
            <Media image={image} flipColumns={flipColumns} animateMedia={animateMedia} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export const Content = ({ subheading, heading, content, link, layout }) => {
  return (
    <div className={`space-y-8 two-col-content ${layout !== 'colour-block' ? 'lg:px-10' : 'pr-6 md:px-0'} `}>
      <div className="space-y-4">
        {subheading && <h6 className="text-h6">{subheading}</h6>}
        {heading && (
          <h2
            className={`${
              layout === 'help-center'
                ? 'text-[25px] leading-[1.40] md:text-[35px] md:leading-[1.29] tracking-[-0.02em] font-extrabold'
                : 'text-h2'
            } mx-auto lg:mx-0`}
          >
            {heading}
          </h2>
        )}
        {content && (
          <div
            className={`${layout === 'help-center' ? 'opacity-100' : 'opacity-60'} p-large`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
      {link && (
        <div className="flex justify-center lg:justify-start">
          <Button link={link} type={ButtonType.Button} color={ColorType.Blue} />
        </div>
      )}
    </div>
  );
};

export const Media = ({ image, flipColumns, layout, direction, animateMedia }) => {
  return (
    <div className={`${flipColumns ? 'lg:order-[-1] ' : ''} two-col-media mx-auto w-full lg:mx-0 `}>
      <InviewMedia direction={animateMedia}>
        <Image image={image} className="w-full h-full gatsby-image" />
      </InviewMedia>
    </div>
  );
};

export const InviewMedia = ({ children, direction = undefined }) => {

  let variantType = undefined;

  const toLeftVariants: Variants = {
    offscreen: {
      x: '100%',
    },
    onscreen: {
      x: '0%',

      transition: {
        duration: 0.8,
      },
    },
  };
  const toRightVariants: Variants = {
    offscreen: {
      x: '-100%',
    },
    onscreen: {
      x: '0%',

      transition: {
        duration: 0.8,
      },
    },
  };

  const fromBottomVariants: Variants = {
    offscreen: {
      y: '100%',
    },
    onscreen: {
      y: '0%',

      transition: {
        duration: 0.8,
      },
    },
  };

  switch (direction) {
    case 'none':
      variantType = undefined;
      break;
    case 'left':
      variantType = toLeftVariants;
      break;
    case 'up':
      variantType = fromBottomVariants;
      break;
    case 'right':
      variantType = fromBottomVariants;
      break;
  }

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        // className="w-full h-full "
        initial="offscreen"
        whileInView="onscreen"
        variants={variantType}
        viewport={{ once: true, margin: '-100px' }}
        // initial={{ x: '100%' }}
        // whileInView={{ x: '0%' }}
        // viewport={{ once: true, amount: 0.8 }}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
};
