import { graphql, useStaticQuery } from 'gatsby';

const useGlobal = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        global {
          optionsGlobal {
            global {
              social {
                link {
                  ...Button
                }
                icon {
                  ...Image
                }
              }
              modal {
                heading
                content
                tabs {
                  label {
                    heading
                    icon {
                      ...Image
                    }
                  }
                  link
                }
                brands {
                  heading
                  subheading
                  logos {
                    ...Image
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data?.wp?.global?.optionsGlobal?.global;
};

export default useGlobal;
