import React, { useEffect, useRef, useState } from 'react';

import CodeBlockTheme from './CodeBlockTheme';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Code = ({ code, language, title }) => {
  const [copied, setCopied] = useState(false);

  const interval = useRef(null);

  useEffect(() => {
    if (copied) {
      interval.current = setInterval(() => {
        setCopied(false);
      }, 3000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [interval, copied]);

  return (
    <div className="mb-[48px] md:mb-[73px] max-w-[792px] mx-auto">
      <div className="bg-dark-purple rounded-t-[8px] text-white border-b border-white border-opacity-20 ">
        <div className="flex justify-between pl-[30px] pr-[20px] items-center">
          <div className="text-[12px] font-semibold leading-[18px] ">{title}</div>
          <div className="border-l border-white border-opacity-20 py-[16px] pl-[20px] relative ">
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
              <button className="hover:text-aqua opacity-[0.5] hover:opacity-100 transition-colors duration-200">
                {' '}
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.2039 14.7586H3.9955C3.38592 14.7586 2.89082 14.2635 2.89082 13.6539V3.27509C2.89082 2.66552 3.38592 2.17041 3.9955 2.17041H12.2039C12.8135 2.17041 13.3086 2.66552 13.3086 3.27509V13.6539C13.3086 14.2635 12.8135 14.7586 12.2039 14.7586ZM12.4404 3.27505C12.4404 3.14449 12.3345 3.03852 12.2039 3.03852H3.9955C3.86494 3.03852 3.75897 3.1445 3.75897 3.27505V13.6538C3.75897 13.7844 3.86495 13.8904 3.9955 13.8904H12.2039C12.3345 13.8904 12.4404 13.7844 12.4404 13.6538V3.27505Z"
                    fill="currentColor"
                  />
                  <path
                    d="M1.82558 0H10.034C10.6436 0 11.1387 0.495109 11.1387 1.10468C11.1387 1.34377 10.9437 1.53876 10.7046 1.53876C10.4655 1.53876 10.2705 1.34377 10.2705 1.10468C10.2705 0.974122 10.1645 0.868155 10.034 0.868155H1.82558C1.69502 0.868155 1.58905 0.974131 1.58905 1.10468V11.4835C1.58905 11.614 1.69502 11.72 1.82558 11.72C2.06467 11.72 2.25965 11.915 2.25965 12.1541C2.25965 12.3932 2.06466 12.5882 1.82558 12.5882C1.216 12.5882 0.720894 12.0931 0.720894 11.4835V1.10468C0.720894 0.495109 1.216 0 1.82558 0Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </CopyToClipboard>
            {copied && (
              <div className="absolute bottom-0 left-[-114%] bg-white text-dark-purple text-[10px] whitespace-nowrap ">
                Copied to clipboard
              </div>
            )}
          </div>
        </div>
      </div>
      <SyntaxHighlighter
        customStyle={{
          marginTop: 0,
          marginBottom: 0,
          background: '#030135',
          border: 0,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          paddingLeft: '40px',
          maxHeight: '400px',
        }}
        language={language}
        showLineNumbers={false}
        wrapLongLines={true}
        style={CodeBlockTheme}
      >
        {code || ''}
      </SyntaxHighlighter>
    </div>
  );
};

export default Code;
