import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '~/components/global/Layout';

import FlexibleContent from '~/components/global/FlexibleContent';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ProductNav from '../global/ProductNav';

export default function defaultPage({
  data: { wpPage, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  return (
    <Layout wp={wp} blogHeader={wpPage?.typePage?.lightHeader} hideCTA={wpPage?.typePage?.hideCta}>
      <Seo post={wpPage} />
      <FlexibleContent content={wpPage?.flexibleContent?.flexibleContent} />
      {wpPage?.typePage?.isProductPage && <ProductNav />}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
