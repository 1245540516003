import React from 'react';

import useGlobal from '~/hooks/useGlobal';
import Image from '~/components/elements/Image';

export default function SocialLinks() {
  const global = useGlobal();

  if (!global.social) return null;

  return (
    <ul className="flex space-x-5">
      {global.social.map((social, i) => (
        <a className="transition-opacity duration-150 hover:opacity-50" key={i} href={social.link.url} target="_blank">
          <div className="screenreader-text">{social.link.title}</div>
          <div className="w-5 h-5">
            <Image image={social.icon} objectFit="contain" />
          </div>
        </a>
      ))}
    </ul>
  );
}
