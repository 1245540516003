import { Link } from 'gatsby';
import React from 'react';
import { WpPage_Flexiblecontent_FlexibleContent_Articles } from 'src/_generated/types';
import { Section } from '~/components/elements/Section';
import Image from '../elements/Image';
import moment from 'moment';

export default function TwoColumns({
  subheading,
  heading,
  section,
  featuredArticle,
  moreArticles,
}: WpPage_Flexiblecontent_FlexibleContent_Articles) {
  return (
    <Section {...section}>
      <div className="container">
        <div className="space-y-5 text-center">
          {subheading && <h6 className="text-h6">{subheading}</h6>}
          <div className="max-w-[660px] mx-auto">{heading && <h2 className="text-h2">{heading}</h2>}</div>
        </div>

        <div className="mt-12 lg:mt-[74px] flex flex-wrap lg:flex-nowrap lg:space-x-6  ">
          <div className="w-full lg:w-7/12 xl:pr-0">
            <FeaturedArticle {...featuredArticle} />
          </div>
          <div className="  w-full lg:w-5/12 flex flex-col space-y-[46px] mt-12 lg:mt-0 ">
            {moreArticles?.map((article, i) => (
              <SimpleArticle key={`moreArticle${i}`} {...article} i={i} />
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}

// TODO: using featuredimage for now at least, not sure if masthead image from post is in graphql yet

export const FeaturedArticle = ({ featuredImage, title, uri, date }) => {
  return (
    <Link to={uri} className="overflow-hidden group">
      <div className="overflow-hidden rounded-[10px] lg:max-w-[610px] ">
        <Image
          image={featuredImage?.node}
          className="rounded-[10px] lg:max-w-[610px] group-hover:scale-110 ease-in-out transition-transform duration-500 overflow-hidden"
        />
      </div>

      <div className="mt-[38px] max-w-[538px] group-hover:opacity-70">
        <h3 className="font-bold text-[28px] leading-[1.18] tracking-[-0.01em] font-body ">{title}</h3>
        <div className="text-[16px]">
          <Author className="flex-wrap" date={date} />
        </div>
      </div>
    </Link>
  );
};

export const SimpleArticle = ({ featuredImage, title, uri, date, i }) => {
  return (
    <Link to={uri} className="hover:opacity-70 group">
      {i === 0 && <div className={` mb-[27px] w-full h-[1px] bg-dark-purple bg-opacity-[0.15] md:hidden "`}></div>}

      <div className="flex space-x-[30px] justify-between">
        <div>
          <h3 className="text-[18px] leading-[1.25] tracking-[-0.01em] font-bold font-body   ">{title}</h3>
          <div className="mt-[5px] text-[14px]">
            <Author className="flex-wrap" date={date} />
          </div>
        </div>
        <Image
          image={featuredImage?.node}
          className="rounded-[10px] w-full h-full max-w-[144px] group-hover:scale-110 ease-in-out transition-transform duration-500 overflow-hidden"
        />
      </div>
      <div className="mt-[27px] w-full h-[1px] bg-dark-purple bg-opacity-[0.15] "></div>
    </Link>
  );
};

// TODO: author name not being queried

export const Author = ({ date, author, className = 'opacity-60' }) => {
  return (
    <div className={`mt-[13px] flex items-center ${className}`}>
      <div className="flex">
        Written by {author} <span className="mx-1"> • </span>{' '}
      </div>
      <div>{moment(date).format('MMM DD, YYYY')}</div>
    </div>
  );
};
