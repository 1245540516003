import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';
import FlexibleContent from '~/components/global/FlexibleContent';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import ResourcesHero from '../resources/ResourcesHero';
import ResourceGrid from '../resources/ResourceGrid';

import Newsletter from '../resources/Newsletter';
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function resouurcesPage({
  data: { wpPage, wp, allWpPost },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const { blogPage } = wpPage;

  return (
    <Layout wp={wp} blogHeader>
      <Seo post={wpPage} />
      {/* <FlexibleContent content={wpPage?.flexibleContent?.flexibleContent} /> */}
      <ResourcesHero hero={blogPage?.hero} />

      <ResourceGrid
        button
        heading="All Articles"
        allWpPost={allWpPost?.nodes?.filter((p) => p.categories.nodes?.some((cat) => cat.name === 'Article'))}
        viewAllTitle="articles"
        viewAllLink="/category/article"
      />

      <Newsletter {...blogPage?.newsletter} />

      <ResourceGrid bgColour="bg-dark-purple" heading="All Content" allWpPost={allWpPost} filter postAmount={9} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Resources($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
      blogPage {
        hero {
          featuredPost {
            ... on WpPost {
              id
              author {
                node {
                  name
                }
              }
            }
          }
          secondaryFeaturedPosts {
            ... on WpPost {
              id
              author {
                node {
                  name
                }
              }
            }
          }
        }
        newsletter {
          heading
          content
        }
      }
    }
    wp {
      ...GeneratedWp
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        uri
        title
        categories {
          nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            ...Image
          }
        }
        date
        author {
          node {
            name
          }
        }
      }
    }
  }
`;
