import React, { useState } from 'react';
import { Link } from 'gatsby';
import { isURL, getURL } from '../../assets/js/utils';

const SmartLink = ({ children, externalIcon, url, title, target, ...props }) => {
  const [isExternal] = useState(isURL(url));

  return (
    <>
      {isExternal && (
        <a href={url} target="_blank" rel="noopener noreferer" {...props}>
          {children}
        </a>
      )}
      {!isExternal && url !== undefined && (
        <Link to={getURL(url)} {...props}>
          {children}
        </Link>
      )}
      {url === undefined && <div {...props}>{children}</div>}
    </>
  );
};

export default SmartLink;
