import React, { Fragment, useState } from 'react';
import useGlobal from '~/hooks/useGlobal';
import { AnimatePresence, motion } from 'framer-motion';

import Image from '../elements/Image';
import { ModalContext } from './Layout';
import { SplitText } from '../elements/animations/SplitText';

const Modal = () => {
  const { modal } = useGlobal();

  const [activeTab, setActiveTab] = useState(null);
  const [activeBG, setActiveBG] = useState(0);

  const gradients = [
    { color1: '#91deef', color2: '#97c0f3', color3: '#8e43ff' },
    { color1: '#95f7fb', color2: '#57c6f0', color3: '#ad82ee' },
    { color1: '#21e3d1', color2: '#89e8b3', color3: '#87f9c4' },
    { color1: '#c1a1f9', color2: '#e167c7', color3: '#5118db' },
    { color1: '#fcf3b3', color2: '#e9db31', color3: '#ff89a3' },
  ];

  const hoverHandler = (i) => {
    setActiveTab(i);
    setActiveBG(i);
  };

  // const modalVariants = {
  //   initial: {
  //     clipPath: 'circle(0% at 90% 5%)',
  //     transition: { duration: 0.4 },
  //   },
  //   animate: {
  //     clipPath: 'circle(100% at 50% 50%)',
  //     transition: { duration: 0.5, staggerChildren: 0.1, ease: 'easeIn' },
  //   },
  //   exit: {
  //     clipPath: 'circle(0% at 10% 5%)',
  //     transition: { duration: 0.5 },
  //   },
  // };
  return (
    <ModalContext.Consumer>
      {(setShowModal) => {
        return (
          <motion.div
            key={`modal`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // variants={modalVariants}
            // initial="initial"
            // animate="animate"
            // exit="exit"
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="fixed top-0 shadow-md left-0 right-0 bottom-0 z-[110] overflow-scroll bg-white scrollbar-hidden  "
          >
            <div className="p-2 mx-auto overflow-scroll bg-white md:px-5 md:py-4 scrollbar-hidden">
              <div className="mx-auto rounded-md ">
                <motion.div
                  transition={{ duration: 0.6, ease: 'easeOut', stiffness: 300, damping: 100 }}
                  animate={{
                    background: `linear-gradient(138deg, ${gradients[activeBG].color1}, ${gradients[activeBG].color2}, ${gradients[activeBG].color3})`,
                  }}
                  className={`rounded-md `}
                >
                  <div className="relative items-center pt-24 md:pt-[10rem] noisy">
                    <div className="absolute top-[22px] left-[24px] md:top-[30px] md:left-[44px] z-[200]">
                      <motion.div
                        whileHover="hover"
                        initial="rest"
                        animate="rest"
                        onClick={() => setShowModal(false)}
                        className="flex cursor-pointer items-center group leading-[22px] font-normal"
                      >
                        <motion.div className={`rotate-180 mr-[8.5px]   `}>
                          <svg
                            width="17"
                            height="13"
                            viewBox="0 0 17 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <motion.path
                              variants={{
                                hover: {
                                  opacity: 1,
                                  scale: 1,
                                  transition: { delay: 0.1 },
                                },
                                rest: {
                                  opacity: 0,
                                  scale: 0,
                                  transition: { delay: 0 },
                                },
                              }}
                              transition={{ duration: 0.3 }}
                              d="M1 11.6074L11.6066 1.00082"
                              stroke="black"
                              strokeWidth="1.75"
                            />
                            <motion.path
                              variants={{
                                hover: {
                                  opacity: 1,
                                  scale: 1,
                                  transition: { delay: 0.1 },
                                },
                                rest: {
                                  opacity: 0,
                                  scale: 0,
                                  transition: { delay: 0 },
                                },
                              }}
                              transition={{ duration: 0.3 }}
                              d="M0.999999 1L11.6066 11.6066"
                              stroke="black"
                              strokeWidth="1.75"
                            />
                            <motion.path
                              variants={{
                                hover: {
                                  pathLength: 0,
                                },
                                rest: {
                                  pathLength: 1,
                                },
                              }}
                              transition={{ duration: 0.4, delay: 0 }}
                              d="M0 6.45515L15 6.45514M15 6.45514L9.54547 11.9091M15 6.45514L9.54546 1"
                              stroke="black"
                              strokeWidth="1.75"
                            />
                          </svg>
                        </motion.div>
                        Go back
                      </motion.div>
                    </div>
                    <div className="relative flex justify-center w-full px-4 md:px-16 lg:px-24 ">
                      <div className="space-y-8 max-w-[618px] text-center mx-auto">
                        <div className="space-y-6">
                          {modal?.subheading && <h6 className="text-h6">{modal?.subheading}</h6>}
                          {modal?.heading && (
                            <h1 className="text-h1">
                              <SplitText
                                initial={{ y: '100%' }}
                                animate="visible"
                                variants={{
                                  visible: (i) => ({
                                    y: 0,
                                    transition: {
                                      delay: i * 0.1,
                                    },
                                  }),
                                }}
                              >
                                {modal?.heading}
                              </SplitText>
                            </h1>
                          )}
                          {modal?.content && (
                            <div
                              className="p-large max-w-[555px] mx-auto"
                              dangerouslySetInnerHTML={{ __html: modal?.content }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-[49px] container relative pb-[32px]">
                      <div style={{ alignItems: 'stretch' }} className="flex flex-wrap items-center md:justify-center ">
                        {modal?.tabs?.map((tab, i) => (
                          <Fragment key={`tab${i}`}>
                            <motion.a
                              href={tab?.link}
                              target="_blank"
                              whileHover="modalHover"
                              initial="modalRest"
                              whileTap="tap"
                              onClick={() => {
                                if(window && window.dataLayer){
                                  window.dataLayer.push({ event: 'click_shopify_link'})
                                }
                              }}
                              onHoverStart={() => hoverHandler(i)}
                              onHoverEnd={() => setActiveTab(null)}
                              className={`justify-center flex-auto w-full bg-white cursor-pointer  lg:flex lg:w-1/5 ${
                                i === 0 &&
                                'pt-[33px] lg:pt-0 rounded-tl-md lg:rounded-bl-md rounded-tr-md lg:rounded-tr-none'
                              } ${
                                i === modal?.tabs.length - 1 &&
                                'pb-[40px] lg:pb-0 lg:rounded-tr-md rounded-br-md rounded-bl-md lg:rounded-bl-none'
                              } px-[30px]   lg:px-0 lg:pt-0`}
                            >
                              <motion.div
                                className={` flex relative lg:justify-center flex-auto lg:p-[44px] md:text-center  ${
                                  i === 0 && 'rounded-l-md'
                                } ${i === modal?.tabs.length - 1 && 'lg:rounded-tr-md rounded-br-md'} `}
                              >
                                <AnimatePresence>
                                  {activeTab === i && (
                                    <motion.div
                                      layoutId="greybg"
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      transition={{ duration: 0.2 }}
                                      className={`absolute inset-0 bg-[#ECECEC] ${i === 0 && 'rounded-l-md'} ${
                                        i === modal?.tabs.length - 1 && 'lg:rounded-tr-md rounded-br-md'
                                      }`}
                                    ></motion.div>
                                  )}
                                </AnimatePresence>
                                <motion.div
                                  className="relative w-full z-[100]
                                
                                
                                "
                                  variants={{
                                    tap: { scale: 0.95 },
                                  }}
                                >
                                  <div className="flex items-center w-full lg:block pt-[14px] lg:pt-0 pl-[8px] lg:pl-0">
                                    <motion.div
                                      className={`w-full max-w-[55px] lg:max-w-[83px] lg:mx-auto`}
                                      variants={{
                                        modalHover: {
                                          y: -3,
                                        },
                                        modalRest: {
                                          y: 0,
                                        },
                                      }}
                                      transition={{ type: 'spring' }}
                                    >
                                      <Image
                                        image={tab?.label?.icon}
                                        className="w-full max-w-[55px] lg:max-w-[83px] lg:mx-auto"
                                      />
                                    </motion.div>
                                    <div className="lg:mt-[17px] ml-[20px] lg:ml-0 font-heading leading-[1.19] font-bold tracking-[-0.005em]  ">
                                      {tab?.label?.heading}
                                    </div>
                                  </div>
                                  {i !== modal?.tabs.length - 1 && (
                                    <div className="mt-[14px] bg-black bg-opacity-20 h-[1px] w-full block lg:hidden"></div>
                                  )}
                                </motion.div>
                              </motion.div>
                            </motion.a>
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>

                <div className="bg-white px-4 md:px-[55px]  md:pt-[42px] pb-[168px] relative flex justify-center">
                  <div className=" max-w-[994px]">
                    <div className="mt-[54px] md:mt-[17px]">
                      <div className="text-[25px] leading-[1.56] text-center font-bold tracking-[-0.02em] md:tracking-[-0.005em]">
                        <h4 className="">{modal?.brands?.heading}</h4>
                        {modal?.brands?.subheading && <h4 className="md:mt-[10px] ">{modal?.brands?.subheading}</h4>}
                      </div>
                    </div>

                    <div className="mt-[44px] md:mt-[83px] items-center grid grid-cols-3 md:grid-cols-6 gap-[35px] md:gap-[50px] lg:gap-[92px] ">
                      {modal?.brands?.logos?.map((logo, i) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 0.4,
                            delay: (i + 1) / 12,
                            ease: 'easeIn',
                          }}
                          key={`brands${i}`}
                        >
                          <Image image={logo} className="w-full max-w-[60px] md:max-w-[91px] mx-auto brands-logo" />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        );
      }}
    </ModalContext.Consumer>
  );
};

export default Modal;
