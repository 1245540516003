import React from 'react';
import { FlexibleLayout } from '~/components/flexible';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';

function removeLayoutNamePrefix(prefix, name) {
  return name.replace(prefix, '');
}

function putContentInWrapperSections(content) {
  const sections = [];
  let currentSection = [];
  let lastBg = null;
  for (let component of content) {
    const bg = component?.section?.backgroundColour || 'white';
    if (lastBg !== null && lastBg !== bg) {
      sections.push(currentSection);
      currentSection = [];
    }
    currentSection.push(component);
    lastBg = bg;
  }
  sections.push(currentSection);
  return sections;
}

export const FlexibleContent = ({
  prefix = 'Page_Flexiblecontent_FlexibleContent_',
  content,
}: {
  prefix: string;
  content: WpPage_Page_FlexibleContent;
}) => {
  if (!content) return null;

  const contentInWrapperSections = putContentInWrapperSections(content);

  return (
    <>
      {contentInWrapperSections.map((content, i) => (
        <div key={i} className="content-wrapper" data-components={content.length}>
          {content.map(({ fieldGroupName, ...layoutProps }, index) => {
            if (!fieldGroupName) {
              console.log(`Flexible layout fieldName not found, please configure graphql for components`);
              return null;
            }
            const layoutName = removeLayoutNamePrefix(prefix, fieldGroupName);
            if (layoutProps.section) {
              // Fill in some useful data for section rendering
              const previousLayout = content[index - 1];
              const nextLayout = content[index + 1];
              layoutProps.section.isFirst = index === 0;
              layoutProps.section.isLast = index === content.length - 1;
              layoutProps.section.previousSection =
                previousLayout && previousLayout.section ? previousLayout.section : null;
              layoutProps.section.nextSection = nextLayout && nextLayout.section ? nextLayout.section : null;
              layoutProps.section.layoutName = layoutName;
            }

            return <FlexibleLayout key={index} layoutName={layoutName} {...layoutProps} />;
          })}
        </div>
      ))}
    </>
  );
};

export default FlexibleContent;
