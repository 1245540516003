import React from 'react';
import { Button, ButtonType, ColorType } from '../elements/buttons/Button';

export default function FooterCta({ title, link }) {
  return (
    <div style={{ backgroundSize: '250%' }} className="relative default-blue-gradient">
      <div className="noisy">
        <div className="container relative py-20 md:py-32 ">
          <div className="max-w-[640px] mx-auto text-center space-y-8 ">
            {title && <h2 className="text-h2">{title}</h2>}
            {link && (
              <div className="flex justify-center">
                <Button link={link} type={ButtonType.Button} color={ColorType.Blue} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
