import React, { useEffect, createContext, useState } from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import { AnimatePresence } from 'framer-motion';
import CookieConsent from 'react-cookie-consent';

import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';

import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';
import Modal from './Modal';

export const ModalContext = createContext(null);

export default function Layout({ wp, children, blogHeader = false, hideCTA = false }) {
  const seo = useSiteInfoAssets();
  const [showModal, setShowModal] = useState(false);

  const globalContent = wp.global.optionsGlobal.global;

  useEffect(() => {
    if(showModal){
      if(window && window.dataLayer){
        window.dataLayer.push({ event: 'open_add_to_store_modal'})
      }
    }
  }, [showModal]);

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <ModalContext.Provider value={setShowModal}>
        <Header data={wp?.header} global={globalContent} blogHeader={blogHeader} />
        <main className="relative">
          <AnimatePresence exitBeforeEnter initial={false}>
            {showModal && <Modal />}
          </AnimatePresence>
          {children}
        </main>
        <Footer data={wp?.footer} global={globalContent} hideCTA={hideCTA} />
     
        <CookieConsent
        
          location="bottom"
          buttonText="Accept"
          cookieName="Ablestar"
          style={{ background: '#3369FB' }}
          buttonStyle={{ color: '#000000', backgroundColor: '#FFFFFF', fontSize: '13px' }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{' '}
        </CookieConsent>
      </ModalContext.Provider>
    </SEOContext.Provider>
  );
}
