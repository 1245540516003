import React from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { Button, ButtonType, ColorType } from '~/components/elements/buttons/Button';
import { WpPage_Flexiblecontent_FlexibleContent_Team } from 'src/_generated/types';
import { Icon } from '../elements/Icon';

export default function Team({ section, heading, subheading, members }: WpPage_Flexiblecontent_FlexibleContent_Team) {
  return (
    <Section {...section}>
      <div className={`container `}>
        <div className="mx-auto text-center">
          <h6 className="text-h6">{subheading}</h6>
          <h2 className="text-h2 mt-[18px] max-w-[917px] mx-auto">{heading}</h2>
        </div>

        <div className="mt-[84px]">
          <div className="flex flex-wrap ml-[-10px] md:ml-[-35px] gap-y-[20px] md:gap-y-[46px]">
            {members?.map((member, i) => (
              <div key={`member${i}`} className="pl-[10px] md:pl-[35px] w-1/2 md:w-1/3">
                <TeamMember {...member?.teamMember} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  );
}

export const TeamMember = ({ image, name, jobTitle, links }) => {
  return (
    <>
      <Image image={image} />
      <div className="mt-[15px] md:mt-[10px] ">
        <h4 className="text-h4">{name}</h4>
        <div className="lg:mt-[-10px] flex items-center space-x-[9px]">
          {links &&
            links?.map((link, i) => (
              <a key={`memberLink${i}`} href={link?.link?.url} target="_blank">
                <Icon type={link?.icon} classes={'about-social hover:opacity-60'} />
              </a>
            ))}
          <div className="text-[16px] md:text-[20px] leading-[1.60] text-dark-blue tracking-[-0.005em] ">
            {jobTitle}
          </div>
        </div>
      </div>
    </>
  );
};
