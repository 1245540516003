import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import FooterCta from '~/components/flexible/FooterCta';
import Image from '~/components/elements/Image';
import SocialLinks from '~/components/elements/SocialLinks';
import SmartLink from './SmartLink';

export default function Footer({ data, hideCTA }) {
  const { footer, footerCta } = data.optionsFooter;
  const [uptimeData, setData] = useState([]);

  useEffect(() => {
    let raw = '';

    const requestOptions = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api.uptimerobot.com/v2/getMonitors?api_key=ur1202422-a3ab9f4b6b225a2ddaf4f8be', requestOptions)
      .then((response) => response.json())
      .then((result) => setData(result))
      .catch((error) => console.log('error', error));
  }, []);

  let shopifyAppsID = 787298019;

  const shopifyApps = uptimeData?.monitors?.filter((e) => e.id === shopifyAppsID);

  return (
    <>
      {!hideCTA && <FooterCta {...footerCta} />}
      <footer role="contentinfo">
        <div className="container py-16 lg:pt-[118px] lg:pb-[105px] space-y-12 md:space-y-24 lg:space-y-48">
          <div className="flex flex-col justify-between space-y-12 lg:space-y-0 lg:flex-row">
            <div className="max-w-[185px] space-y-5">
              <div className="w-[120px]">
                <Image image={footer.logo} />
              </div>
              <div className="opacity-60">{footer.description}</div>
              <SocialLinks />
            </div>
            {footer.columns && <FooterNavs columns={footer.columns} />}
          </div>
          <SubFooter {...footer} service={shopifyApps} />
        </div>
      </footer>
    </>
  );
}

export const FooterNavs = ({ columns }) => {
  return (
    <div className="flex flex-col w-full space-y-12 md:space-y-0 md:flex-row lg:w-auto md:space-x-28">
      {columns.map((column, z) => (
        <div className="space-y-4" key={z}>
          {column.menus.map((menu, i) => (
            <div key={i}>
              {menu.heading && (
                <>
                  {menu.headingIsLink ? (
                    <SmartLink url={menu.heading.url}>
                      <h5 className="transition-colors duration-200 ease-in-out text-h5 hover:text-blue">
                        {menu.heading.title}
                      </h5>
                    </SmartLink>
                  ) : (
                    <h5 className="text-h5 mb-[16px]">{menu.heading.title}</h5>
                  )}
                </>
              )}
              <nav>
                <ul className="space-y-2">
                  {menu?.submenu?.map((item, q) => (
                    <li key={q}>
                      <SmartLink
                        url={item.link.url}
                        className="transition-opacity duration-200 ease-in-out opacity-60 hover:opacity-100 hover:text-blue"
                      >
                        {item.link.title}
                      </SmartLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const SubFooter = ({ copyright, privacyMenu, serviceStatusLabel, service }) => {
  return (
    <div className="border-t border-t-dark-purple border-opacity-50 pt-16 flex flex-col md:flex-row justify-between text-[14px]">
      {/* @todo: Change year to a dynamic variable */}
      <div className="opacity-60 mt-8 md:mt-0 order-10 lg:order-[0]">{copyright}</div>
      <div className="flex items-center space-x-5 ">
        <nav>
          {privacyMenu && (
            <ul className="flex">
              {privacyMenu.map((item, i) => (
                <div className="opacity-60 hover:opacity-100" key={i}>
                  <Link to={item?.link?.url} className="" dangerouslySetInnerHTML={{ __html: item?.link?.title }} />

                  <div className=" bg-black w-full h-[1px] mt-[-3px] "></div>
                </div>
              ))}
            </ul>
          )}
        </nav>
        <div className="flex items-center space-x-2">
          <div
            className={`w-2 h-2 rounded-full bg-[#20FFA1] ${
              service && service[0]?.status === 2
                ? 'bg-[#20FFA1]'
                : service && service[0]?.status == 0
                ? 'bg-[#f7c111]'
                : 'bg-[#f80b0b]'
            } `}
          ></div>
          <span>{serviceStatusLabel}</span>
        </div>
      </div>
    </div>
  );
};
