import { motion, LazyMotion, m, domAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import { ModalContext } from '~/components/global/Layout';
import SmartLink from '~/components/global/SmartLink';
import { WpAcfLink } from '~/_generated/types';
import { Icon, IconType } from '../Icon';

export enum ButtonType {
  Button = 'button',
  Text = 'text',
}

export enum ColorType {
  Black = 'black',
  White = 'white',
  Blue = 'blue',
}

export interface ButtonProps {
  link: WpAcfLink;
  type: ButtonType;
  icon: string;
  fullWidth?: boolean;
}

export const Button = ({ link, type, color, icon, fullWidth, modal, className }: ButtonProps) => {
  if (!link) return null;

  let colorClasses = '';
  let typeClasses = '';

  if (type === ButtonType.Button) {
    typeClasses = 'rounded-sm py-3 px-9 tracking-[-.005rem] ';
    colorClasses = 'bg-white text-black hover:bg-black hover:text-white';
    if (color === ColorType.Black) {
      colorClasses = 'bg-black text-white hover:bg-white hover:text-black';
    } else if (color === ColorType.White) {
      colorClasses = 'bg-white text-black hover:bg-black hover:text-white';
    } else if (color === ColorType.Blue) {
      colorClasses = 'bg-blue text-white hover:bg-black hover:text-white';
    }
  } else if (type === ButtonType.Text) {
    typeClasses = 'text-link';
  } else {
    typeClasses = 'hover:opacity-50 transition-opacity';
  }

  const iconMotion = {
    rest: {
      // x: 0,
      // rotate: 0,
      // scale: 1,
      transition: {
        duration: 0.4,
        type: 'tween',
        ease: 'easeIn',
        scale: { duration: 0.1 },
      },
    },
    hover: {
      // x: 10,
      // scale: 1.2,
      rotate: -180,
      transition: {
        duration: 0.4,
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };
  return (
    <ModalContext.Consumer>
      {(setShowModal) => {
        return (
          <LazyMotion features={domAnimation}>
            <m.div
              whileTap={{ scale: 0.99 }}
              initial="rest"
              whileHover="hover"
              animate="rest"
              className="u-button md:max-w-max"
              onClick={
                () => setShowModal(modal || link?.url === '#add' ? true : 
                link?.url.includes('shopify.com') ? () => {
                  if(window && window.dataLayer){
                    window.dataLayer.push({ event: 'click_shopify_link'})
                  }
                } : undefined
              )}
            >
              <SmartLink
                url={link?.url || '#'}
                className={`${
                  fullWidth ? 'flex justify-center' : 'inline-flex'
                } items-center space-x-[13px] transition-colors duration-200 ease-in-out  ${colorClasses} ${typeClasses} ${className} `}
              >
                <span className="font-heading">{link?.title}</span>
                {type === ButtonType.Button && (
                  <m.span variants={iconMotion} className="mt-[1px]">
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <motion.path
                        variants={{
                          rest: {
                            d: 'M0.556641 6.32617L15.5566 6.32617',
                            pathLength: 1,
                            opacity: 1,
                            fill: 'none',
                            stroke: 'currentColor',

                            transition: {
                              duration: 0.2,
                              pathLength: { delay: 0.2, duration: 0.4, ease: 'easeIn' },
                              stroke: { delay: 0.25 },
                            },
                          },
                          hover: {
                            pathLength: 0,
                            opacity: 0,

                            stroke: 'none',
                            transition: { duration: 0.4, pathLength: { duration: 0.2 } },
                          },
                        }}
                        strokeWidth="1.75"
                        d="M8.96171 6.666C9.3925 7.08306 9.8567 7.45343 10.3503 7.78092C9.8567 8.10841 9.3925 8.47879 8.96171 8.89585C8.51396 9.32933 8.11403 9.80189 7.76087 10.312C7.40771 9.80188 7.00778 9.32932 6.56002 8.89584C6.12922 8.47878 5.66502 8.10841 5.17144 7.78092C5.66502 7.45344 6.12923 7.08306 6.56002 6.666C7.00778 6.23252 7.40771 5.75996 7.76087 5.2498C8.11403 5.75996 8.51396 6.23252 8.96171 6.666Z"
                        stroke="currentColor"
                      />
                      <motion.path
                        variants={{
                          rest: {
                            // d: 'M9.54541 12.4546L15 7.00004L9.54541 1.5455',
                            d: 'M10.2109 0.962891L15.5566 6.3457L10.2109 11.6901',
                            fill: 'none',
                            strokeWidth: '1.75',
                            pathLength: 1,

                            transition: {
                              duration: 0.4,
                              pathLength: { delay: 0.5, duration: 0.4, ease: 'easeIn' },
                              strokeWidth: { delay: 0.6 },
                            },
                          },
                          hover: {
                            strokeWidth: '0',
                            pathLength: 0,

                            d: 'M0 7.78092C0 7.90125 0.0802004 7.94136 0.1604 7.94136C4.2915 8.74351 6.8986 11.0296 7.6205 15.4014C7.6205 15.4816 7.7007 15.5618 7.7809 15.5618C7.8611 15.5618 7.9012 15.4816 7.9012 15.4014C8.6232 11.0296 11.2302 8.74351 15.3613 7.94136C15.4415 7.94136 15.5217 7.86114 15.5217 7.78092C15.5217 7.70071 15.4415 7.62049 15.3613 7.62049C11.2302 6.81834 8.6232 4.53219 7.9012 0.160431C7.9012 0.080216 7.821 0 7.7408 0C7.6606 0 7.6205 0.080216 7.6205 0.160431C6.8986 4.53219 4.2915 6.81834 0.1604 7.62049C0.0802004 7.62049 0 7.6606 0 7.78092Z',
                            fill: 'currentColor',
                            transition: { duration: 0.4 },
                          },
                        }}
                        d="M0 7L15 7"
                        stroke="currentColor"
                        strokeWidth="1.75"
                      />
                    </svg>
                  </m.span>
                )}
              </SmartLink>
            </m.div>
          </LazyMotion>
        );
      }}
    </ModalContext.Consumer>
  );
};

export const AnimatedArrow = ({}) => {};
