import React from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { WpPage_Flexiblecontent_FlexibleContent_Social } from 'src/_generated/types';
import useGlobal from '~/hooks/useGlobal';

export default function Social({
  section,
  heading,
  subheading,
  description,
  image,
}: WpPage_Flexiblecontent_FlexibleContent_Social) {
  const { social } = useGlobal();

  return (
    <Section {...section}>
      <div className={`container`}>
        <div className="flex justify-center text-center">
          <div>
            <h6 className="text-h6">{subheading}</h6>
            <h2 className="text-h2 mt-[7px]">{heading}</h2>
            <div className="mt-[22px] p-large opacity-60">{description}</div>
          </div>
        </div>
        <div className="mt-[67px] w-full relative">
          <Image image={image} className="w-full h-full" />

          <div className="absolute flex justify-center w-full lg:bottom-[-2rem]">
            <div className="text-white bg-blue text-[15px] font-bold flex items-center rounded ">
              <div className="py-[18px] px-[30px] flex items-center">
                <div>Follow our socials</div>

                <div className="flex items-center space-x-[9px] ml-[12px]">
                  {social?.map((soc, i) => (
                    <a
                      key={`soc${i}`}
                      href={soc?.link?.url}
                      className="bg-white rounded-full p-[11px] group transition-colors duration-200 hover:bg-black"
                    >
                      <Image image={soc?.icon} className="h-[14px] w-[14px] group-hover:invert" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
