import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Section } from '~/components/elements/Section';
import Image from '~/components/elements/Image';
import { WpPage_Flexiblecontent_FlexibleContent_Features } from 'src/_generated/types';
import { Icon, IconType } from '../elements/Icon';
import { AnimatePresence, domAnimation, LazyMotion, m, motion, useAnimation } from 'framer-motion';
import { backGroundSwitcher } from '../utils/backgroundSwitcher';
import { useInView } from 'react-intersection-observer';
import { useWindowSize } from 'react-use';

import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, EffectFade } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';

export default function TwoColumns({
  subheading,
  heading,
  content,
  items,
  section,
  layout,
  carouselBackgroundColour,
}: WpPage_Flexiblecontent_FlexibleContent_Features) {
  return (
    <Section {...section}>
      {layout === 'product' ? (
        <ProductLayout subheading={subheading} heading={heading} content={content} items={items} />
      ) : layout === 'carousel' ? (
        <CarouselLayout
          subheading={subheading}
          heading={heading}
          content={content}
          items={items}
          backgroundColour={carouselBackgroundColour}
        />
      ) : layout === 'about' ? (
        <AboutLayout subheading={subheading} heading={heading} content={content} items={items} />
      ) : (
        <DefaultLayout subheading={subheading} heading={heading} content={content} items={items} />
      )}
    </Section>
  );
}

export const DefaultLayout = ({ heading, content, subheading, items }) => {
  return (
    <LazyMotion features={domAnimation}>
      <div className="container space-y-12 md:space-y-20">
        <div className="space-y-5 text-center">
          {subheading && <h6 className="text-h6">{subheading}</h6>}
          <div className="max-w-[566px] mx-auto">{heading && <h2 className="text-h2">{heading}</h2>}</div>
          <div className="max-w-[750px] mx-auto opacity-60">
            {content && <div className="p-large" dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        </div>
        <div className="flex flex-wrap justify-center xl:flex-nowrap space-8 xl:space-x-16 gap-y-[56px]">
          {items.map((item, i) => (
            <div key={i} className={`w-full md:w-1/2 xl:w-1/3 ${i === items?.length - 1 && 'last-feature'}`}>
              <Feature {...item} i={i} />
            </div>
          ))}
        </div>
      </div>
    </LazyMotion>
  );
};

export const AboutLayout = ({ heading, content, subheading, items }) => {
  const topLeftVariants = {
    hidden: {
      x: 20,
      y: 20,
    },
    inView: {
      x: 0,
      y: 0,
    },
  };
  const topRightVariants = {
    hidden: {
      x: -20,
      y: 20,
    },
    inView: {
      x: 0,
      y: 0,
    },
  };
  const bottomLeftVariants = {
    hidden: {
      x: 20,
      y: -20,
    },
    inView: {
      x: 0,
      y: 0,
    },
  };
  const bottomRightVariants = {
    hidden: {
      x: -20,
      y: -20,
    },
    inView: {
      x: 0,
      y: 0,
    },
  };

  const variants = [topLeftVariants, topRightVariants, bottomLeftVariants, bottomRightVariants];

  const controls = useAnimation();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  useEffect(() => {
    if (inView) {
      controls.start('inView');
    }
  }, [controls, inView]);

  return (
    <LazyMotion features={domAnimation}>
      <div className="relative">
        <div className="green-gradient">
          <div className=" noisy">
            <div className="container relative space-y-12 md:space-y-20 pt-[91px] pb-[97px] ">
              <div className="space-y-5">
                {subheading && <h6 className="text-h6">{subheading}</h6>}
                <div className="max-w-[566px]">{heading && <h2 className="text-h2">{heading}</h2>}</div>
                <div className="max-w-[550px] opacity-60">
                  {content && <div className="p-large" dangerouslySetInnerHTML={{ __html: content }} />}
                </div>
              </div>
              <div className="mt-[73px] flex flex-wrap ml-[-25px] gap-y-[25px] " ref={ref}>
                {items.map((item, i) => (
                  <m.div
                    key={`aboutFeat${i}`}
                    variants={variants[i]}
                    initial="hidden"
                    animate={controls}
                    // whileInView="inView"
                    viewport={{ once: true, amount: 'all' }}
                    transition={{ duration: 0.6, type: 'spring', bounce: 0.3 }}
                    className="pl-[25px] w-full flex-auto  md:w-1/2"
                  >
                    <AboutFeature {...item} />
                  </m.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyMotion>
  );
};

export const Feature = ({ heading, content, image, i }) => {
  const featureVariants = {
    hidden: {
      opacity: 0,
    },
    inView: {
      opacity: 1,
    },
  };

  const headingVariants = {
    hidden: {
      y: 10,
    },
    inView: {
      y: 0,
    },
  };

  const { width } = useWindowSize();

  return (
    <m.div
      className="flex justify-center w-full h-full"
      key={`feat${i}`}
      variants={featureVariants}
      whileInView="inView"
      initial={width < 768 ? 'inView' : 'hidden'}
      viewport={{ once: true, margin: '-200px' }}
      transition={{ duration: 0.4, delay: i / 8, ease: 'easeIn' }}
    >
      <div className="flex flex-col justify-between w-full text-center space-y-7">
        {image && <Image image={image} className={`  `} />}
        <div className=" feature-content !mt-[-50px] z-10">
          {heading && (
            <m.h4
              variants={headingVariants}
              transition={{ duration: 0.4, delay: i / 7, ease: 'easeIn' }}
              className="text-h4 mt-2.5 "
            >
              {heading}
            </m.h4>
          )}
          {content && (
            <m.p
              className="md:max-w-[357px] mx-auto"
              variants={headingVariants}
              transition={{ duration: 0.4, delay: i / 12, ease: 'easeIn' }}
            >
              {content}
            </m.p>
          )}
        </div>
      </div>
    </m.div>
  );
};

export const AboutFeature = ({ heading, content, image }) => (
  <div className="bg-white rounded-[10px] h-full ">
    <div className="pl-[41px] py-[55px]  pr-[41px] lg:pr-[80px]">
      <Image image={image} className="max-w-[50px]" />
      <div className="mt-[21px] space-y-3">
        {heading && <h4 className="text-h4 !leading-[1.20] max-w-[356px]">{heading}</h4>}
        {content && <div className="opacity-60" dangerouslySetInnerHTML={{ __html: content }} />}
      </div>
    </div>
  </div>
);

export const ProductLayout = ({ heading, content, subheading, items }) => {
  return (
    <div className="container flex flex-wrap ">
      <div className="inline-block w-full space-y-5 xl:w-1/2 ">
        {subheading && <h6 className="text-h6">{subheading}</h6>}
        <div className="md:max-w-[620px]">{heading && <h2 className="text-h2">{heading}</h2>}</div>
        <div className="md:max-w-[444px] opacity-60">
          {content && <div className="p-large" dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </div>
      <div className="inline-block mt-12 xl:mt-0 space-y-[18px] xl:space-y-[39px] xl:ml-[-45px] mx-auto w-full xl:w-1/2">
        <div className="w-full mx-auto xl:max-w-max">
          <ProductFeature {...items[0]} startDelay={0.1} />
        </div>
        <div className="xl:flex xl:space-x-[49px] space-y-[18px] xl:space-y-[0] justify-center">
          {items?.slice?.(1, 3)?.map((item, i) => (
            <ProductFeature
              key={`product${i}`}
              {...item}
              i={i}
              startDelay={i === 0 ? 0.3 : 0.6}
              opacityDelay={i === 0 ? 0.6 : 0.9}
              scaleDelay={i === 0 ? 0.5 : 0.8}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export const CarouselLayout = ({ heading, content, subheading, items, backgroundColour }) => {
  const [activeTab, setActiveTab] = useState(0);

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setActiveTab((i) => (i + 1) % items.length);
    }, 5000);

    setActiveTab(0);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval]);

  const overrideActive = (i) => {
    setActiveTab(i);
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      setActiveTab((i) => (i + 1) % items.length);
    }, 5000);
  };

  let gradientColour;

  gradientColour = backGroundSwitcher(backgroundColour?.backgroundColour);

  return (
    <div className="container space-y-12 md:space-y-20">
      <div className="space-y-4 text-center">
        {subheading && <h6 className="text-h6">{subheading}</h6>}
        <div className="max-w-[916px] mx-auto carousel-heading">
          {heading && <h2 className="text-h2">{heading}</h2>}
        </div>
        <div className="max-w-[818px] mx-auto opacity-60">
          {content && <div className="p-large" dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </div>

      <div className="mt-[119px] flex flex-wrap ml-[-61px] flex-col-reverse items-center lg:flex-row  ">
        <div className="w-full lg:w-1/2 xl:w-5/12 pl-[61px] mt-12 lg:mt-0">
          {items.map((item, i) => (
            <div key={`item${i}`} onClick={() => overrideActive(i)}>
              <CarouselBlock i={i} {...item} isActive={activeTab === i} />
            </div>
          ))}
        </div>
        <div className="w-full lg:w-1/2 xl:w-7/12 pl-[61px] rounded-[8px] overflow-hidden transform-gpu flex flex-auto  ">
          <div
            className={` rounded-[8px] overflow-hidden transform-gpu ${gradientColour} flex flex-auto w-[370px] h-[400px] lg:h-[535px] relative `}
          >
            <div className="flex items-center justify-center w-full h-full noisy">
              <div className="w-full h-full">
                <Images
                  slides={items}
                  active={activeTab}
                  fade
                  imageClass="overflow-hidden transform-gpu carousel-image w-full h-full mx-auto  max-h-[400px] lg:max-h-[535px] "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Images = ({ slides, active, rounded, fade, imageClass }) => {
  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => swiper?.slideTo(index);

  useLayoutEffect(() => {
    slideTo(active);
  }, [active]);

  return (
    <div className="h-full">
      <Swiper
        onSwiper={setSwiper}
        modules={[A11y, EffectFade]}
        className={` w-full h-full ${rounded && 'rounded-[10px] overflow-hidden  '}`}
        slidesPerView={1}
        draggable={false}
        effect={fade ? 'fade' : 'slide'}
        fadeEffect={{ crossFade: true }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={`tabbedswiper${i}`} style={{ height: '100%' }} className="change-trans-opacity">
            <div className="relative flex items-center justify-center w-full h-full aspect-h-535 aspect-w-370">
              <Image
                image={slide.image}
                loading="eager"
                objectFit="cover"
                className={`w-full h-full ${imageClass} absolute`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export const CarouselBlock = ({ icon, heading, content, isActive, i }) => {
  return (
    <div
      className={`${
        isActive ? 'bg-white' : 'bg-transparent hidden md:block'
      } bg-opacity-[0.08] transition-colors duration-300 ease-out cursor-pointer relative text-white`}
    >
      <div className="flex pt-[29px] pl-[24px] md:pl-[29px] pb-[35px] pr-[20px] md:pr-[36px] space-x-[21px]">
        <div className="w-[27px] h-[27px]">
          <Icon type={icon} classes="w-[27px] h-[27px]" />
        </div>

        <div className="text-white">
          <h5 className="text-h5 ">{heading}</h5>
          <div className="mt-[10px] opacity-60 tracking-[-0.005em]" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            key={`bg${i}`}
            initial={{ height: '0%', opacity: 1 }}
            animate={{ height: '100%' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 5, ease: 'linear' }}
            className="absolute w-[6px] h-full left-0 top-0 bg-blue"
          ></motion.div>
        )}
        {isActive && (
          <div className="absolute w-[6px] h-full left-0 top-0 bg-[#C4C4C4] text-white bg-opacity-[0.28] "></div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const ProductFeature = ({ heading, content, icon, subheading, startDelay, opacityDelay, scaleDelay, i }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  const { width } = useWindowSize();

  const controls = useAnimation();

  const featureVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };

  const textVariants = {
    initial: {
      scale: 0,
      display: 'none',
    },
    animate: {
      scale: 1,
      display: 'block',
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.4,
        opacity: { delay: opacityDelay, duration: 1 },
        scale: { delay: scaleDelay },
      },
    },
  };
  const leftDiagonal = {
    initial: {
      x: 100,
      y: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,

      transition: {
        delay: 0.4,
        duration: 0.9,

        opacity: { duration: 0.6 },
      },
    },
  };

  const rightDiagonal = {
    initial: {
      x: -100,
      y: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      y: 0,
      opacity: 1,

      transition: {
        // delay: 0.5,
        duration: 0.9,
      },
    },
  };

  const right = {
    initial: {
      x: -100,

      opacity: 0,
    },
    animate: {
      x: 0,

      opacity: 1,
      // scale: 1,
      // display: 'block',
      // opacity: 1,
      transition: {
        delay: 0.6,
        duration: 0.9,
        opacity: { duration: 0.6 },
        // opacity: { delay: opacityDelay, duration: 1 },
        // scale: { delay: scaleDelay },
      },
    },
  };

  const up = {
    initial: {
      y: 100,

      opacity: 0,
    },
    animate: {
      y: 0,

      opacity: 1,
      // scale: 1,
      // display: 'block',
      // opacity: 1,
      transition: {
        duration: 0.9,
        opacity: { duration: 0.6 },
        // opacity: { delay: opacityDelay, duration: 1 },
        // scale: { delay: scaleDelay },
      },
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start('animate');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="bg-[#F1F1F1] h-[fit-content] rounded-[10px] inline-block md:min-w-[330px] xl:max-w-[330px] w-full"
      initial={width > 768 ? 'initial' : null}
      animate={controls}
      variants={i === 0 ? leftDiagonal : i === 1 ? right : up}
    >
      <motion.div
        className="space-y-1 px-[37px] py-[30px] 
      "
        // initial="initial"
        // animate="start"
        // variants={textVariants}
      >
        <Icon classes={`w-[27px] h-[27px]`} type={icon} />

        <div className="">{heading && <h5 className="text-h5 mt-[30px]">{heading}</h5>}</div>
        <div className=" xl:max-w-[270px] opacity-60">
          {content && <div className="" dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </motion.div>
    </motion.div>
  );
};
