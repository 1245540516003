import React, { useRef, useState } from 'react';
import Image from '../elements/Image';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Icon, IconType } from '../elements/Icon';

const ImageCarousel = ({ images }) => {
  const flexibleSlider = useRef();

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="mb-[48px] md:mb-[73px] ">
      <Swiper
        className="flexible-slider"
        ref={flexibleSlider}
        grabCursor={true}
        onActiveIndexChange={(i) => setActiveTab(i.activeIndex)}
      >
        {images?.map((image, i) => (
          <SwiperSlide slidesPerView={1} className="relative z-0 w-full overflow-hidden " key={`blogSlide${i}`}>
            <Image image={image} objectFit="cover" />
          </SwiperSlide>
        ))}

        <div className="absolute bottom-[5%] right-[2%] z-10 flex space-x-[10px]">
          <div
            onClick={() => flexibleSlider.current.swiper.slidePrev()}
            className={`cursor-pointer rotate-180 ${activeTab === 0 && 'opacity-20'} `}
          >
            <Icon type={IconType.NavArrow} />
          </div>
          <div
            onClick={() => flexibleSlider.current.swiper.slideNext()}
            className={`cursor-pointer ${activeTab === images?.length - 1 && 'opacity-20'} `}
          >
            <Icon type={IconType.NavArrow} />
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
