import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Icon, IconType } from '../Icon';
import CheckboxField from './CheckboxField';
import InputField from './InputField';
import TextAreaField from './TextArea';
import { Link } from 'gatsby';
import MultiOptionSelect from './MultiOptionSelectField';

const validationSchema = Yup.object({
  department: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  company: Yup.string(),
  jobFunction: Yup.string().required('Required'),
  message: Yup.string(),
  acceptTerms: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export const ContForm = ({ setIsSubmitted }) => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Formik
      initialValues={{
        department: 'sales',
        name: '',
        email: '',
        company: '',
        jobFunction: '',
        message: '',
        acceptTerms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const formData = new FormData();

        for (var key in values) {
          formData.append(key, values[key]);
        }

        fetch('https://getform.io/f/8a1b75b5-5324-4ace-8b30-a63ed4d547e8', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              setIsSubmitted(true);
            }
            console.log(response);
          })
          .catch((error) => {
            setErrorMessage(error);

            console.log(error);
          });
      }}
    >
      {(props) => (
        <Form className="">
          <div className="flex justify-center">
            <MultiOptionSelect
              name="department"
              options={[
                {
                  label: 'Sales',
                  value: 'sales',
                },
                {
                  label: 'Support',
                  value: 'support',
                },
                {
                  label: 'Other',
                  value: 'other',
                },
              ]}
            />
          </div>
          <div className="mb-6 -ml-6 md:flex md:mt-9">
            <div className="w-full pl-6 mb-6 md:mb-0">
              <InputField name="name" label="Name*" placeholder="Enter name..." />
            </div>
            <div className="w-full pl-6">
              <InputField name="email" label="Email*" placeholder="Enter email address..." />
            </div>
          </div>

          <div className="mb-6"></div>

          <div className="mb-6 -ml-6 md:flex">
            <div className="w-full pl-6 mb-6 md:mb-0">
              <InputField name="company" label="Company Name" placeholder="Enter company name..." />
            </div>
            <div className="w-full pl-6 mb-6 md:mb-0">
              <InputField name="jobFunction" label="Job function*" />
            </div>
          </div>
          <div className="mb-6">
            <TextAreaField name="message" label="Message" placeholder="Enter message..." />
          </div>
          <CheckboxField
            dark
            className=""
            name="acceptTerms"
            label={
              <div>
                I have read the{' '}
                <Link className="underline " to="/privacy-policy/">
                  Privacy Policy
                </Link>{' '}
                and agree to its terms.*
              </div>
            }
          />

          <div className="flex justify-start mt-11">
            <button
              type="submit"
              className="bg-purple-2 rounded-[2px] text-[16px] transition-colors duration-200 ease-out border border-black py-[13px] px-[35px] hover:bg-dark-purple hover:text-white"
            >
              <div className="flex items-center justify-center w-full">
                <span>Submit</span>
                <div className="inline-block ml-[16px]">
                  <Icon type={IconType.ArrowRight} />
                </div>
              </div>
            </button>
          </div>
          {errorMessage?.length > 0 && (
            <div className="mt-4 text-red" dangerouslySetInnerHTML={{ __html: errorMessage }} />
          )}
        </Form>
      )}
    </Formik>
  );
};
