import React from 'react';
import TwoColumns from './TwoColumns';
import Features from './Features';
import Masthead from './Masthead';
import Apps from './Apps';
import Articles from './Articles';
import Testimonials from './Testimonials';
import OneColumn from './OneColumn';
import Team from './Team';
import Benefits from './Benefits';
import Jobs from './Jobs';
import Social from './Social';
import Plans from './Plans';
import Pricing from './Pricing';
import Wysiwyg from '../resources/Wysiwyg';
import MediaWithCaption from '../resources/MediaWithCaption';
import ImageCarousel from '../resources/ImageCarousel';
import InterstitialArticle from '../resources/InterstitialArticle';
import Quote from '../resources/Quote';
import Code from '../resources/Code';

export const FlexibleLayout = (props: { layoutName: String }) => {
  const { layoutName } = props;

  // We 'as any' these but we could grab the types from the generated files;

  switch (layoutName) {
    case 'TwoColumns':
      return <TwoColumns {...(props as any)} />;
    case 'Masthead':
      return <Masthead {...(props as any)} />;
    case 'Features':
      return <Features {...(props as any)} />;
    case 'Apps':
      return <Apps {...(props as any)} />;
    case 'Articles':
      return <Articles {...(props as any)} />;
    case 'Testimonials':
      return <Testimonials {...(props as any)} />;
    case 'OneColumn':
      return <OneColumn {...(props as any)} />;
    case 'Team':
      return <Team {...(props as any)} />;
    case 'Benefits':
      return <Benefits {...(props as any)} />;
    case 'Jobs':
      return <Jobs {...(props as any)} />;
    case 'Social':
      return <Social {...(props as any)} />;
    case 'Plans':
      return <Plans {...(props as any)} />;
    case 'Pricing':
      return <Pricing {...(props as any)} />;
    case 'Wysiwyg':
      return <Wysiwyg {...(props as any)} />;
    case 'MediaWithCaption':
      return <MediaWithCaption {...(props as any)} />;
    case 'ImageCarousel':
      return <ImageCarousel {...(props as any)} />;
    case 'InterstitialArticle':
      return <InterstitialArticle {...(props as any)} />;
    case 'Quote':
      return <Quote {...(props as any)} />;
    case 'Code':
      return <Code {...(props as any)} />;
    default:
      console.log(`Flexible layout mapping not found: ${layoutName}`);
      return null;
  }
};
